import React, { useState, useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import breakpointSizes from "../../../constants/breakpointSizes";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { createIntent } from "../../../api/payment";
import CheckoutForm from "./checkoutForm";

const PaymentModal = ({ open, handleClose, id }) => {
  const dispatch = useDispatch();

  const publishKey = process.env.REACT_APP_PUBLISHABLE_KEY;
  const { secretKey } = useSelector((state) => state.payment);

  const [clientSecret, setSecretClientKey] = useState(null);

  const stripePromise = useMemo(() => loadStripe(publishKey), [publishKey]);

  const clientSecrets = secretKey?.clientSecret;
  useEffect(() => {
    if (open && !clientSecret) {
      dispatch(createIntent({ id }));
      setSecretClientKey(clientSecrets);
    }
  }, [dispatch, clientSecrets, id, open, clientSecret]);
  
  return (
    <Modal open={open} onClose={handleClose}>
      <PaymentWrapper>
        <h1>Niyo Hair & Beauty Payment</h1>
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )}
      </PaymentWrapper>
    </Modal>
  );
};

export default PaymentModal;

const PaymentWrapper = styled.div`
  border-radius: 6px;
  width: 60%;
  margin: 0 auto;
  @media ${breakpointSizes.mobile} {
    width: 90%;
    border-radius: 10px !important;
  }
  margin-top: 20vh;
  padding: 20px;
  background: #fff;
  h1 {
    text-align: center;
  }
`;
