import React, { useState, useEffect } from "react";
import ProgressLayout from "../../components/ProgressLayout";
import { Paragraph, NiyoButton } from "../../styles/StyledComponents";
import HairLoook from "../../asset/redHair.png";
import { InformationLayout, InputField, SelectField } from "./style";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirstName,
  setLastName,
  setEmail,
  setPostCode,
  setPhoneDetails,
  setAddress,
  setReceiveBookingReminders,
  setDialCode,
  setShortCode,
  setCountryName,
} from "../../globalState/reducers/appointmentSlice";
import { bookAppointment, updateAppointment } from "../../api/appointment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

const Information = () => {
  const {
    preferenceData,
    firstName,
    lastName,
    email,
    data,
    postCode,
    phoneDetails,
    houseAddress,
    address,
    receiveBookingReminders,
    dialCode,
    discount,
    referral,
    shortCode,
    countryName,
    information,
    loading,
    error,
    unknownError,
  } = useSelector((state) => state?.appointment);

  const {
    year,
    month,
    date,
    day,
    hairId,
    uniqueHairId,
    newHairStylePreferenceData,
  } = useSelector((state) => state.hairstyle);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [savedData, setData] = useState();
  const [id, setId] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  const requiredField = newHairStylePreferenceData.filter(
    (data) => data?.validations?.isRequired
  );

  useEffect(() => {
    if (submitted) {
      if (error !== null && typeof error !== "string") {
        error &&
          error?.map((err) => {
            return err?.errors?.map((error) => {
              return toast.error(
                `${error?.name} is required or check ${error?.name} field `,
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
            });
          });
      }
      const preferId = localStorage.getItem("preferId");
      if (error === "please atleast select required preference") {
        navigate(`/preference/${preferId}`);
        setTimeout(() => {
          // eslint-disable-next-line array-callback-return
          requiredField.map((field) => {
            toast.error(`${field?.title} preference is required`, {
              position: toast.POSITION.TOP_CENTER,
            });
          });
        }, 2000);
      }
      if (unknownError >= 500) {
        toast.error(`There Seem To Be An Issue.. Please Contact Admin`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (
        error !== "please atleast select required preference" &&
        typeof error === "string"
      ) {
        toast.error(`There Seem To Be An Issue.. Please Contact Admin`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }, [error, unknownError]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setSubmitted(false);
  }, []);

  useEffect(() => {
    if (data !== null && information) {
      navigate("/payment");
    }
  }, [data, navigate, information]);

  useEffect(() => {
    const run = () => {
      setData(preferenceData);
      setId(hairId);
    };
    run();
  }, [preferenceData, hairId]);

  const handleChangeAddress = (val) => {
    const conversionVal = JSON.parse(val);
    dispatch(setAddress(conversionVal));
  };

  const handlePhoneNumber = (value, country, event, formattedValue) => {
    dispatch(setDialCode(country.dialCode));
    dispatch(setCountryName(country.name));
    dispatch(setPhoneDetails(formattedValue));
    dispatch(setShortCode(country.countryCode));
  };

  const filteredData = savedData?.map(
    ({
      _id,
      hair,
      subTitle,
      __v,
      createdAt,
      updatedAt,
      validations,
      ...rest
    }) => rest
  );

  useEffect(() => {
    const localStorageData = localStorage.getItem("Info");

    if (localStorageData) {
      setBookingId(localStorageData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check if filteredData is undefined or empty, then navigate to home page

    if (filteredData?.length === 0 || !uniqueHairId) {
      navigate("/");
    }
  }, [uniqueHairId, filteredData, navigate]);

  const isFormValid = () => {
    // Check if all required fields are filled
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      postCode.trim() !== "" &&
      email.trim() !== "" &&
      phoneDetails.trim() !== "" &&
      address !== ""
    );
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const { checked } = receiveBookingReminders;
    const appointmentValues = {
      user: {
        firstName,
        lastName,
        email,
        phoneNumber: {
          code: dialCode,
          number: phoneDetails,
          country: {
            name: countryName,
            shortCode: shortCode,
          },
        },
        postCode,
      },
      preferenceData: {
        preferences: filteredData,
        schedule: {
          month,
          year,
          day,
          time: date,
        },
      },
      bookingExtras: {
        receiveBookingReminders: checked,
        isHomeService: address,
        address: houseAddress,
      },
      promo: {
        referralCode: referral,
        discountCode: discount,
      },
    };

    dispatch(bookAppointment({ appointmentValues, id }));
  };

  const handleUpdate = () => {
    setSubmitted(true);
    const { checked } = receiveBookingReminders;
    const appointmentValues = {
      user: {
        firstName,
        lastName,
        email,
        phoneNumber: {
          code: dialCode,
          number: phoneDetails,
          country: {
            name: countryName,
            shortCode: shortCode,
          },
        },
        postCode,
      },
      preferenceData: {
        preferences: filteredData,
        schedule: {
          month,
          year,
          day,
          time: date,
        },
      },
      bookingExtras: {
        receiveBookingReminders: checked,
        isHomeService: address,
        address: houseAddress,
      },
      promo: {
        referralCode: referral,
        discountCode: discount,
      },
    };

    dispatch(updateAppointment({ appointmentValues, id, bookingId }));
  };

  return (
    <>
      <InformationLayout>
        <ToastContainer />
        <div className="informationWrapper">
          <div className="forms">
            <ProgressLayout />
            <Paragraph>
              Step 4 - Kindly fill in your personal information{" "}
              <i>(Payment is after service)</i>
            </Paragraph>
            <form>
              <div className="form-line">
                <label>
                  First Name
                  <InputField
                    name="firstName"
                    value={firstName}
                    onChange={(e) => dispatch(setFirstName(e.target.value))}
                    className=""
                    placeholder="Enter your First Name"
                  ></InputField>
                </label>
                <label>
                  Last Name
                  <InputField
                    name="lastName"
                    value={lastName}
                    onChange={(e) => dispatch(setLastName(e.target.value))}
                    className=""
                    placeholder="Enter your Last Name"
                  ></InputField>
                </label>
              </div>
              <div className="form-line">
                <label>
                  Post Code
                  <InputField
                    name="postCode"
                    value={postCode}
                    onChange={(e) => dispatch(setPostCode(e.target.value))}
                    className=""
                    placeholder="45210"
                  ></InputField>
                </label>
                <label>
                  Phone Number
                  <PhoneInput
                    placeholder="+1 513 4556"
                    value={phoneDetails}
                    onChange={handlePhoneNumber}
                    country={"gb"}
                    enableSearch={true}
                    className="form-phone entry"
                    required
                  />
                </label>
              </div>
              <div className="form-line">
                <label>
                  Email Address
                  <InputField
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => dispatch(setEmail(e.target.value))}
                    className=""
                    placeholder="Enter your email address"
                  ></InputField>
                </label>
                <label>
                  Would you prefer home service in the future
                  <SelectField
                    id="home-service"
                    value={address}
                    placeholder="Select your option"
                    onChange={(e) => handleChangeAddress(e.target.value)}
                  >
                    <option selected disabled value="">
                      Select your option
                    </option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </SelectField>
                </label>
              </div>

              <div className="notify">
                <div>
                  <input
                    type="checkbox"
                    className="radio"
                    checked={receiveBookingReminders.checked}
                    onChange={() =>
                      dispatch(
                        setReceiveBookingReminders({
                          ...receiveBookingReminders,
                          checked: !receiveBookingReminders.checked,
                        })
                      )
                    }
                    value={receiveBookingReminders.checked}
                    name="receiveBookingReminders"
                  />
                </div>
                <div>You will recieve booking reminders via email</div>
              </div>
            </form>
            {bookingId ? (
              <NiyoButton
                width="80%"
                height="55px"
                fontSize="13px"
                disabled={!isFormValid() || loading}
                onClick={handleUpdate}
                title={
                  !isFormValid() ? "Please fill all required fields" : null
                }
              >
                {loading ? <Spin spinning /> : "Update Information"}
              </NiyoButton>
            ) : (
              <NiyoButton
                width="80%"
                height="55px"
                fontSize="13px"
                disabled={!isFormValid() || loading}
                onClick={handleSubmit}
                title={
                  !isFormValid() ? "Please fill all required fields" : null
                }
              >
                {loading ? <Spin spinning /> : "Next"}
              </NiyoButton>
            )}
          </div>
          <div className="image">
            <div className="des">
              <div>
                “Great service, hair was done beautifully. definitely booking
                again”
              </div>
              <p>Jasmin Maccal</p>
            </div>
            <img src={HairLoook} alt="hairlook" />
          </div>
        </div>
      </InformationLayout>
    </>
  );
};

export default Information;
