import React from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from './style'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Mark from '../../asset/tick.svg'
import logo2 from '../../asset/dashboard/logo.svg'
import logo from '../../asset/header/niyo-pink-logo.svg'

const ProgressLayout = () => {
  const { catId } = useSelector((state) => state.hairstyle)
  const { preferenceData, data } = useSelector((state) => state.appointment)
  const location = useLocation()
  const path = location.pathname

  const ExternalLink = ({ to, children }) => {
    const navigateExternal = () => {
      window.open(to, '_blank'); 
    };
  
    return <span onClick={navigateExternal}>{children}</span>;
  };

  return (
    <Layout>
      {localStorage.getItem('userAccess') ? (
        <ExternalLink to="https://www.niyohairandbeauty.com">
          <img src={logo2} alt="NHB Logo" className="niyo-logo" />
        </ExternalLink>
      ) : (
        <ExternalLink to="https://www.niyohairandbeauty.com">
          <img src={logo} alt="NHB Logo" className="niyo-logo" />
        </ExternalLink>
      )}
      <div className="tabs">
        <ul>
          <Link to={`/`}>
            <li
              className={
                path.includes('booking') && catId
                  ? 'done'
                  : catId
                  ? 'done'
                  : path.includes('booking')
                  ? 'active'
                  : ''
              }
            >
              {catId && (
                <div>
                  <img src={Mark} alt="mark" />
                </div>
              )}
              {!catId && <div>1</div>}
              <div>Pick Hair Style </div>
            </li>
          </Link>
          {catId && (
            <Link to={`/preference/${catId}`}>
              <li
                className={
                  path.includes('preference') && preferenceData !== null
                    ? 'done'
                    : preferenceData !== null
                    ? 'done'
                    : path.includes('preference')
                    ? 'active'
                    : ''
                }
              >
                {preferenceData !== null && (
                  <div>
                    <img src={Mark} alt="mark" />
                  </div>
                )}{' '}
                {preferenceData === null && <div>2</div>}
                <div>Select your preference </div>
              </li>
            </Link>
          )}
          {!catId && (
            <li
              className={
                path.includes('preference') && preferenceData !== null
                  ? 'done'
                  : preferenceData !== null
                  ? 'done'
                  : path.includes('preference')
                  ? 'active'
                  : ''
              }
            >
              {preferenceData !== null && (
                <div>
                  <img src={Mark} alt="mark" />
                </div>
              )}{' '}
              {preferenceData === null && <div>2</div>}
              <div>Select your preference </div>
            </li>
          )}

          {preferenceData !== null && (
            <Link to={`/information`}>
              <li
                className={
                  path.includes('information') && data !== null
                    ? 'done'
                    : data !== null
                    ? 'done'
                    : path.includes('information')
                    ? 'active'
                    : ''
                }
              >
                {data !== null && (
                  <div>
                    <img src={Mark} alt="mark" />
                  </div>
                )}{' '}
                {data === null && <div>3</div>}
                <div> Your information </div>
              </li>
            </Link>
          )}
          {preferenceData === null && (
            <li
              className={
                path.includes('information') && data !== null
                  ? 'done'
                  : data !== null
                  ? 'done'
                  : path.includes('information')
                  ? 'active'
                  : ''
              }
            >
              {data !== null && (
                <div>
                  <img src={Mark} alt="mark" />
                </div>
              )}{' '}
              {data === null && <div>3</div>}
              <div>Your information </div>
            </li>
          )}

          {data !== null && (
            <Link to={`/payment`}>
              <li className={path.includes('payment') ? 'active' : ''}>
                <div>4</div> <div>Confirm & Pay</div>
              </li>
            </Link>
          )}
          {data === null && (
            <li className={path.includes('payment') ? 'active' : ''}>
              <div>4</div> <div>Confirm & Pay</div>
            </li>
          )}
        </ul>
      </div>
    
    </Layout>
  )
}

export default ProgressLayout
