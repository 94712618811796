import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

export const FooterContainer = styled.div`
  background-color: #2c2c2c;
  min-height: 600px;
  padding: 30px 80px 30px 80px;

  .footer-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .footer-column {
      height: 350px;
      li{
        list-style: none;
        margin-bottom: 20px;;
      }

      a {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        font-size: 16px;
        margin: 0 0 10px 0;
      }

      a:visited {
        color: #fff;
      }
    }

    .footer-column-1 {
      width: 15%;
      justify-content: space-between;

      .footer-niyo-logo {
        width: 94px;
        height: 34px;
      }

      .innovative {
        margin-top:100px;
      }
      .social-icons {
        display: flex;
        flex-direction: row;
        margin-top:100px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #fff;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          margin: 0 10px 0 0;

          .social-icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .footer-column-2 {
      width: 25%;
    }

    .footer-column-3,
    .footer-column-4,
    .footer-column-5 {
      width: 15%;
    }
  }

  .copyright-section {
    text-align: center;

    span {
      color: grey;
    }
  }

  @media ${breakpointSizes.mobile} {
    width:100%;
    flex-wrap: wrap;
    padding: 30px 20px 30px 20px;
    h4{
      font-size:16px;
    }
    .footer-columns {
      flex-wrap: wrap;
      .footer-column{
        width:50%;
        height: 200px;
        .innovative {
          margin-top:10px;
        }
        .social-icons{
          margin-top:20px;
          .icons{
            height: 30px;
          width: 30px;
          .social-icon {
            width: 15px;
            height: 15px;
          }
          }
        }
      }
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`;
