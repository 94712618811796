import { createSlice } from "@reduxjs/toolkit";
import {
  hairstyle,
  hairstylePreference,
  hairstyleCat,
  catHairstyle,
  allHairstyles,
} from "../../api/hairstyle";

const initialState = {
  loading: false,
  error: null,
  allHair: null,
  data: false,
  catData: null,
  hairData: false,
  preferenceData: null,
  totalPrice: 0,
  date: "",
  stage: "preferences",
  year: null,
  uniqueHairId: null,
  month: null,
  day: null,
  hairId: "",
  catId: "",
  selectedImage: null,
  preferId: null,
  name: null,
  price: null,
  time: null,
  hairIds: null,
  dateVal: null,
  hairStylePreferenceData: [],
  newHairStylePreferenceData: [],
};

const hairstyleSlice = createSlice({
  name: "hairstyle",
  initialState,
  reducers: {
    setCatId: (state, action) => {
      const payload = action.payload;
      state.catId = payload;
    },
    setHairId: (state, action) => {
      const payload = action.payload;
      state.hairId = payload;
    },
    setTotalPrice: (state, action) => {
      const payload = action.payload;
      state.totalPrice = payload;
    },
    setDateVal: (state, action) => {
      const payload = action.payload;
      state.dateVal = payload;
    },
    setHairIds: (state, action) => {
      const payload = action.payload;
      state.hairIds = payload;
    },
    setStage: (state, action) => {
      const payload = action.payload;
      state.stage = payload;
    },
    setUniqueHairId: (state, action) => {
      const payload = action.payload;
      state.uniqueHairId = payload;
    },
    setTimes: (state, action) => {
      const payload = action.payload;
      state.time = payload;
    },
    setName: (state, action) => {
      const payload = action.payload;
      state.name = payload;
    },
    setPrice: (state, action) => {
      const payload = action.payload;
      state.price = payload;
    },
    setSelectedImage: (state, action) => {
      const payload = action.payload;
      state.selectedImage = payload;
    },
    setPreferId: (state, action) => {
      const payload = action.payload;
      localStorage.setItem("preferId", payload);
      const preferId = localStorage.getItem("preferId");
      state.preferId = preferId;
    },
    setHairStylePreferenceData: (state, action) => {
      const payload = action.payload;
      state.hairStylePreferenceData = payload;
    },
    setNewHairStylePreferenceData: (state, action) => {
      const payload = action.payload;
      state.newHairStylePreferenceData = payload;
    },
    getPreferenceHairstyleData: (state, action) => {
      const payload = action.payload;
      state.preferenceData = payload;
    },
    setTime: (state, action) => {
      const payload = action.payload;
      state.date = payload;
    },
    setYear: (state, action) => {
      const payload = action.payload;
      state.year = payload;
    },
    setMonth: (state, action) => {
      const payload = action.payload;
      state.month = payload;
    },
    setDay: (state, action) => {
      const payload = action.payload;
      state.day = payload;
    },
  },
  extraReducers: {
    [hairstyleCat.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [hairstyleCat.fulfilled]: (state, action) => {
      state.loading = false;
      state.catData = action.payload;
    },
    [hairstyleCat.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [catHairstyle.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [catHairstyle.fulfilled]: (state, action) => {
      state.loading = false;
      state.hairData = action.payload;
    },
    [catHairstyle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [hairstyle.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [hairstyle.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [hairstyle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [allHairstyles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [allHairstyles.fulfilled]: (state, action) => {
      state.loading = false;
      state.allHair = action.payload;
    },
    [allHairstyles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [hairstylePreference.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [hairstylePreference.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [hairstylePreference.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default hairstyleSlice.reducer;

export const {
  getPreferenceHairstyleData,
  setDate,
  setYear,
  setMonth,
  setTime,
  setDay,
  setCatId,
  setHairId,
  setPreferId,
  setDateVal,
  setHairStylePreferenceData,
  setNewHairStylePreferenceData,
  setTotalPrice,
  setUniqueHairId,
  setSelectedImage,
  setName,
  setPrice,
  setTimes,
  setHairIds,
  setStage,
} = hairstyleSlice.actions;
