import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import { NiyoButton, Paragraph } from '../../../styles/StyledComponents'
import { CardBody } from '../../../styles/StyledComponents'
import { nanoid } from "nanoid";
import searchIcon from '../../../asset/dashboard/search.svg'
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material'
import { referral, getDiscount } from '../../../api/dashboard'
import { useNavigate } from 'react-router-dom'
import { sortBySearch } from '../../../globalState/reducers/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import menuPink from '../../../asset/dashboard/menuPinkIcon.svg'
import { ReactComponent as Copy } from '../../../asset/dashboard/copy.svg'
import profileIcon from '../../../asset/dashboard/ProfileIcon.svg'
import discountImage from '../../../asset/dashboard/discount-shape.svg'
import { TableContainer, TablePagination } from '@mui/material'
import logo from '../../../asset/dashboard/logo.svg'
import breakpointSizes from '../../../constants/breakpointSizes'
import BackArrrow from '../../../asset/dashboard/back.png'
import Cancel from '../../../asset/dashboard/x.png'
import Icon from '../../../asset/dashboard/Icon.png'
import Icon2 from '../../../asset/dashboard/icon2.png'
import Copy1 from '../../../asset/dashboard/copy-success.png'

const columns = [
  {
    id: 'Name of User Referred',
    label: 'Name of User Referred',
  },
  { id: 'Date Joined', label: 'Date Joined' },
]

const SpecificUser = () => {
  const { discount, refData, searchedReferral, error } = useSelector((state) => state.dashboard)
  const dispatch = useDispatch()

  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const [searchRef, setSearchRef] = useState('')
  const [modalStage, setModalStage] = useState('first')

  const [rowsPerPage, setRowsPerPage] = useState(2)

  const navigate = useNavigate()

  useEffect(() => {
    function fetchData() {
      dispatch(referral())
      dispatch(getDiscount())
    }
    fetchData()
  }, [dispatch])

  function handleClick() {
    navigate(-1)
  }

  const handleCancel = () => {
    setModalStage('first')
    setOpen(false)
  }

  const textRef = useRef(null)
  const textRef2 = useRef(null)

  const copyToClipboard = () => {
    const textToCopy = textRef.current.innerText
    navigator.clipboard.writeText(textToCopy)
  }

  const copyToClipboard2 = () => {
    const textToCopy = textRef2.current.innerText
    navigator.clipboard.writeText(textToCopy)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const handleSearchTeam = (e) => {
    setSearchRef(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  const requestDiscount = () => {
    setModalStage('second')
    dispatch(getDiscount())
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const filteredRef = refData?.referred?.filter((ref) => {
    return (
      ref?.firstName?.toLowerCase().indexOf(searchedReferral?.toLowerCase()) !== -1
    )
  })


  const latestData =
    filteredRef &&
    filteredRef
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

      const case1 =
      searchRef === ''
        ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : latestData
  return (
    <SpecificUserStyle>
       {error && (
        <div className="errorMsg">
          <div>
            <Paragraph>
              There seems to be an issue processing your request from the server
            </Paragraph>
            <NiyoButton onClick={() => window.location.reload(true)}>
              please reload or try again later
            </NiyoButton>
          </div>
        </div>
      )}
      {
        !error && (
          <>
            <div className="wrapper">
              <div className="headers">
                <div onClick={handleClick}>
                  <img src={BackArrrow} alt="NHB Logo" className="niyo-logo" />
                </div>
                <Link to="/">
                  <img src={logo} alt="NHB Logo" className="niyo-logo" />
                </Link>
              </div>
              <div className="user-stat">
                <div>
                  <div>
                    <h5>Referral Code</h5>
                    <div
                      className="copy"
                      onClick={() => {
                        copyToClipboard2()
                      }}
                    >
                      <Copy />
                      <span ref={textRef2}>{refData?.code}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="discountHeader">
                    <div>
                      <img src={profileIcon} alt="profileIcon" />
                    </div>
                    <p>No of Referrals</p>
                  </div>
                  <h1>{refData?.referred?.length || 0}</h1>
                  <div onClick={() => setOpen(true)} className="request">
                    <div>Request for Discount</div>
                    <div>
                      <img src={Icon2} alt="cancel" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="discountHeader">
                    <div>
                      <img src={discountImage} alt="discount" />
                    </div>
                    <p>Redeemed</p>
                  </div>
                  <h1>{refData?.redeemed?.length}</h1>
                </div>

                <div>
                  <div className="discountHeader">
                    <div>
                      <img src={discountImage} alt="discount" />
                    </div>
                    <p>Discount Used</p>
                  </div>
                  <h1>{discount?.length}</h1>
                </div>
              </div>

              <div className="heading">
                <h3>Users Referred</h3>
              </div>
              <CatWrapperStyle rounded="8px" referral="40px">
                <TableContainer>
                  <div className="initialSort">
                    <SearchWrapper referralGridBg="#FFF9FD">
                      <div className="search-input">
                        <img src={searchIcon} alt="search Icon" />
                        <input
                          type="text"
                          value={searchRef}
                          onChange={handleSearchTeam}
                          placeholder="Search..."
                        />
                      </div>
                      <div className="tableHeader">
                        <div className="pagination">
                          <span>Show</span>
                          <TablePagination
                            rowsPerPageOptions={[2, 4, 6]}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage=""
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />

                          <p>entries</p>
                        </div>
                        <div className="grid-view active">
                          <img
                            src={menuPink}
                            alt="format List"
                            className="grid-img"
                          />
                        </div>
                      </div>
                    </SearchWrapper>
                  </div>
                  <CatTableStyle
                    specific={true}
                    border="transparent"
                    referralColor="#2C2C2C"
                  >
                    <Table aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell key={nanoid()}>{column.label}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!refData ? (
                          <div>There are currently no user reffered</div>
                        ) : (
                              case1?.map((row) => {
                                return (
                                  <TableRow hover key={nanoid()}>
                                    <TableCell>
                                      {row?.firstName} {row?.lastName}
                                    </TableCell>
                                    <TableCell> { '---'}</TableCell>
                                  </TableRow>

                                )
                              })
                        )}
                      </TableBody>
                    </Table>
                  </CatTableStyle>
                </TableContainer>
              </CatWrapperStyle>
              {!refData && (
                <div className="footer">
                  <TablePagination
                    rowsPerPageOptions={[2, 4, 6]}
                    component="div"
                    count={refData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              )}
            </div>

            <CustomModal
              centered
              closable={false}
              open={open}
              onCancel={handleCancel}
              footer={null}
              width={600}
            >
              <CardBody border={'none'} width={'600px'}>
                <div className="head">
                  <div>
                    {modalStage === 'last' ? (
                      <img src={Copy1} alt="copy" />
                    ) : (
                      <img src={Icon} alt="icon" />
                    )}
                  </div>
                  <div onClick={handleCancel}>
                    <img src={Cancel} alt="cancel" />
                  </div>
                </div>
                {modalStage === 'first' && (
                  <div className="first">
                    <p>Redeem Discount</p>
                    <p>You are eligible to get 10% for referring 20 people</p>
                    <div className="btn">
                      <div onClick={handleCancel}>Cancel</div>
                      <div onClick={requestDiscount}>Request for DIscount Code</div>
                    </div>
                  </div>
                )}
                {modalStage === 'second' && (
                  <div className="second">
                    {discount?.length > 0 && (
                      <>
                        <div>
                          <div>
                            <h5>Your Discount Code</h5>
                            <div className="copy">
                              <Copy />
                              <span ref={textRef}>{refData?.code}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="clip"
                          onClick={() => {
                            setModalStage('last')
                            copyToClipboard()
                          }}
                        >
                          Copy to clipboard
                        </div>
                      </>
                    )}
                    {discount?.length < 1 && (
                      <>
                        <div>
                          You have not completed the minimum referral for a discount
                        </div>
                      </>
                    )}
                  </div>
                )}
                {modalStage === 'last' && (
                  <div className="last">
                    <p>Discount Code copied to clipboard</p>
                    <p>You can use your code on our checkout</p>
                  </div>
                )}
              </CardBody>
            </CustomModal>
          
          </>
        )
      }
    </SpecificUserStyle>
  )
}

export default SpecificUser
export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-input {
    width: 45%;
    position: relative;
    input {
      border: 1px solid #e3e6e9;
      padding: 16px;
      padding-left: 45px;
      width: 100%;
      font-size: 14px;
      background-color: transparent;
      border-radius: 8px;
      &::placeholder {
        font-size: 14px;
        color: #4e5054;
      }
    }
    img {
      position: absolute;
      left: 15px;
      top: 15px;
    }
  }
  .tableHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 14px;
      color: #858585;
    }
    .pagination {
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      margin-right: 16px;
      span {
        color: #858585;
        font-size: 12px;
      }
      .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
        padding: 0;
      }
      .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
        cursor: pointer;
        border: 1px solid rgba(230, 232, 234, 0.62);
        margin: 0;
        padding: 0;
        background: #fff;
        color: #858585;
        svg {
          margin-right: 5px;
          font-size: 13px;
        }
        .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
          padding: 8.5px 12px;
          background: #fff;
          margin-right: 14px;
        }
      }
    }
    .menu {
      margin: 0 28px 0 20px;
      cursor: pointer;
    }
    .grid-view {
      cursor: pointer;
      border-radius: 4px;
      padding: 12px;
    }
    .active {
      background-color: #f5f9ff;
    }
  }
`

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    background: #fafafa;
    width: 100%;
    margin: 116px 0;
  }
  .clip {
    border-radius: 6px;
    display: flex;
    margin-top: 60px;
    margin-right: 0;
    margin-left: auto;
    background: var(--neutral-900, #171717);
    color: white;
    padding: 10px;
    justify-content: flex-end;
    width: fit-content;
    cursor: pointer;
  }
  .head {
    display: flex;
    justify-content: space-between;
    div:last-child {
      cursor: pointer;
    }
  }
  .first,
  .last {
    p:first-child {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
    p:last-child {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .btn {
      display: flex;
      gap: 15px;
      justify-content: flex-end;
      text-align: right;
      div:first-child {
        border-radius: 6px;
        border: 1px solid var(--neutral-200, #e5e5e5);
        padding: 10px;
        cursor: pointer;
      }
      div:last-child {
        border-radius: 6px;
        background: var(--neutral-900, #171717);
        color: white;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
`

export const SpecificUserStyle = styled.section`
  .headers {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    div:first-child {
      cursor: pointer;
    }
  }
  .wrapper {
    width: 70%;
    margin: 0 auto;
    @media ${breakpointSizes.mobile} {
      width: 90%;
    margin: 0 auto;
    }
  }
  .initialSort .css-levciy-MuiTablePagination-displayedRows {
    border: 3px solid red !important;
    display: none;
  }
  .initialSort
    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
    .MuiTablePagination-actions {
    display: none;
  }
  .footer .css-pdct74-MuiTablePagination-selectLabel {
    display: none;
  }
  .footer .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    display: none;
  }
  // this is for the next bar
  .footer
    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
    .MuiTablePagination-actions {
    background: black;
    display: flex !important;
    width: 15%;
    height: 60%;
    justify-content: flex-start;
    color: white;
  }
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    color: white !important;
    background: black !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    position: none !important;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    text-align: center;
    flex: none !important;
    font-size: 1.5rem;
    padding: 8px !important;
    border-radius: 0% !important;
    overflow: hidden !important;
  }
  .ant-popover {
    div {
      > div {
        padding: 0 !important;
      }
    }
  }
  .disabled {
    pointer-events: none;
    background-color: #a7a7a7;
    &.preference {
      background-color: inherit;
    }
  }
  .footer .css-jtlhu6-MuiTablePagination-root:last-child {
    // max-width: 100%  !important;
    display: flex !important;
    position: absolute !important;
    right: 0 !important;
    width: 400px !important;
    justify-content: center;
    // border: 3px solid red;
  }
  .footer .css-jtlhu6-MuiTablePagination-root {
    display: flex !important;
    width: 100% !important;
    overflow: hidden !important;
  }
  .footer .css-1psng7p-MuiTablePagination-spacer {
    display: flex !important;
    padding-left: -230px !important;
  }
  .footer .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex !important;
    margin: 36px 0 24px;
  }
  .user-stat {
    margin: 48px 0 32px;
    display: flex;
    gap: 40px;
    div:first-child {
      margin-right: 30px;
    }
      .copy {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 5px;
        }
    .request {
      display: flex;
      cursor: pointer;
      align-items: center;
      // gap: 10px;
      border-radius: 6px;
      border: 1px solid var(--primary-900-main, #0F0F0F);
      padding: 8px;
    }
    h1 {
      font-size: 48px;
      font-style: normal;
      margin-left: 1px;
      margin-top: 4px;
      font-weight: 600;
      // line-height: 140%; 
    }
    .discountHeader {
      display: flex;
      align-items: center;
      // gap: 20px;
    }
    @media ${breakpointSizes.mobile} {
      display: block;
      img {
        width: 30px;
        height: 30px;
      }
    }
    // > div {
    //   background-color: #fff;
    //   border-radius: 16px;
    //   padding: 25px 15px 0px 0px;
    //   display: flex;
    //   //   gap: 24px;
    //   color: #505050;
    //   white-space: nowrap;
    //   h3 {
    //     color: #505050;
    //     font-size: 24px;
    //     font-weight: 400;
    //     @media ${breakpointSizes.mobile} {
    //       font-size: 18px;
    //     }
    //   }
    //   h1 {
    //     font-size: 48px;
    //     font-weight: 600;
    //     margin-top:-10px;
    //     @media ${breakpointSizes.mobile} {
    //       font-size: 18px;
    //     }
    //   }
    //   p {
    //     font-size: 14px;
    //   }
    //   .street {
    //     margin: 10px 0 0;
    //   }
    //   .flex {
    //     margin-bottom: 15px;
    //     gap: 24px;
    //     > div {
    //       display: flex;
    //       align-items: center;
    //       margin-top: 8px;
    //       gap: 8px;
    //       span {
    //         font-size: 12px;
    //       }
    //     }
    //   }
    //   .copy {
    //     display: flex;
    //     align-items: center;
    //     gap: 8px;
    //     margin-top: 5px;
    //   }
    // }
    // > div:nth-child(1) {
    //   width: 47%;
    //   @media ${breakpointSizes.mobile} {
    //     margin-bottom: 20px;
    //   }

    //   h1 {
    //     border-radius: 200px;
    //     background: #0d0d0d;
    //     padding: 19px 14px 19px 14px;
    //     color: #ffc2ef;
    //     font-size: 32px;
    //     font-weight: 700;
    //   }
    //   h3 {
    //     color: #0f0e0e;
    //   }
    //   h5 {
    //     color: #000;
    //     font-size: 14px;
    //     font-weight: 400;
    //   }
    //   p,
    //   span {
    //     font-family: reboundLight;
    //     color: #606060;
    //   }
    // }
    // > div:nth-child(2) {
    //   width: 27%;
    // }
    // > div:nth-child(3) {
    //   //   width: 27%;
    // }
    // > div:nth-child(2),
    // > div:nth-child(3) {
    //   display: flex;
    //   //   gap: 22px;
    //   flex-direction: column;
    //   padding: 24px 15px 0 24px;
    //   @media ${breakpointSizes.mobile} {
    //     padding: 0px;
    //     gap: -10px;
    //   }
    //   div {
    //     display: flex;
    //     align-items: center;
    //     gap: 12px;
    //     h3 {
    //       font-family: rebound;
    //       color: #191919;
    //     }
    //   }
    //   h1 {
    //     // margin-left: 55px;
    //     color: #505050;
    //   }
    // }
  }
  .heading {
    margin-bottom: 24px;
    h3 {
      color: #2c2c2c;
    }
  }
`

export const CatWrapperStyle = styled.div`
  background-color: white;
//   padding: ${({ referral }) => referral || '24px 16px 105px'};
  border-radius: ${({ rounded }) => rounded || '4px'};
  .search-input {
    width: 44%;
  }
`

export const CatTableStyle = styled.div`
  margin-top: 32px;
  background-color: transparent;
  min-height: 400px;
  max-height: 400px;

  table {
    .empty-search {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin: 0 auto;
    }
    thead {
      tr {
        .ant-table-cell {
          border-bottom: 1px solid #eaeaea;
        }
        th:nth-last-child(1) {
          color: ${({ referralColor }) => referralColor || '#858585'};
        }
        th {
          padding-left: ${({ specific }) => specific && 0};
          color: #2c2c2c;
          font-family: rebound;
          border-bottom: 1px solid ${({ border }) => border || 'inherit'};
        }
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: transparent !important;
      }
      td {
        padding-left: ${({ specific }) => specific && 0}!important;
        border-bottom: 1px solid ${({ border }) => border || 'inherit'};
        color: #a8a8a8;
        font-weight: 400;
        padding: 24px 16px;
        text-transform: capitalize;
      }
      .menu-dot {
        div {
          cursor: pointer;
        }
      }
    }
  }
`
