import * as React from 'react'
import { Skeleton } from 'antd'
import styled from 'styled-components'
import { nanoid } from "nanoid";

export function Loader() {
  const arr = [1, 2, 3, 4, 5]

  return (
    <LoadWrapper>
      {arr.map(() => (
          <div className='imgLoader' key={nanoid()}>
            <Skeleton
              paragraph={{
                rows: 0,
              }}
              style={{ width: '140px', height: '140px' }}
              active={true}
              size="default"
            />
          </div>
      ))}
    </LoadWrapper>
  )
}

const LoadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .imgLoader {
      .ant-skeleton-title {
        width: 714px !important;
        height: 250px !important;
        padding: 24px 22px 22px 22px !important;
        border-radius: 4px !important;
      }
  }
`
