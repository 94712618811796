import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Calendar from "../../../asset/dashboard/calendar.png";
import { format } from "date-fns";
import Clock from "../../../asset/dashboard/clock.png";
import closeIcon from "../../../asset/close.svg";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import { Modal, Spin } from "antd";
import { setScheduled } from "../../../globalState/reducers/dashboardSlice";
import dayjs from "dayjs";
import { Checkbox } from "antd";
import { CardBody, NiyoButton } from "../../../styles/StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import breakpointSizes from "../../../constants/breakpointSizes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpcomingBook = ({ upcomingDates, handleBtn, handleBtn2 }) => {
  const { scheduled, cancelled, loading, cancelloader } = useSelector(
    (state) => state.dashboard
  );
  const { daysData } = useSelector((state) => state?.appointment);

  const dispatch = useDispatch();

  const [dayOfWeek, setDayOfWeek] = useState(null);
  const [customTime, setCustomTime] = useState([]);
  const [data2, setData2] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [day, setDay] = useState(false);
  const [time, setTime] = useState("");
  const [month, setMonth] = useState(false);
  const [year, setYear] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [hairId, setHairId] = useState(null);

  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancel2 = () => {
    setOpen2(false);
  };
  const handleOpen = (time, day, month, year, index) => {
    setTime(time);
    setDay(day);
    setMonth(month);
    setYear(year);
    setOpen(true);
    setCurrentIndex(index);
    setSelectedDates((prev) => ({
      ...prev,
      [index]: `${year}-${month}-${day}`,
    }));
  };

  useEffect(() => {
    const currentDate = dayjs();
    let dayOfWeek = currentDate.day();

    const getNextAvailableDay = (dayIndex) => {
      for (let i = 1; i <= 7; i++) {
        const nextDayIndex = (dayIndex + i) % 7;
        const nextDayName = getDayOfWeekName(nextDayIndex);

        const dayData = daysData?.find((day) => day.day === nextDayName);
        if (dayData && !dayData.blocked) {
          return nextDayName;
        }
      }
      return null;
    };

    const nextDayName = getNextAvailableDay(dayOfWeek);
    if (nextDayName) {
      setDayOfWeek(nextDayName);
    }
  }, []);

  useEffect(() => {
    let customTimes = [];

    const selectedDayData = daysData?.find(
      (dayData) => dayData?.day === dayOfWeek
    );
    if (selectedDayData) {
      const { openTime, closeTime } = selectedDayData;

      const openTimeDate = new Date(`1970-01-01T${openTime}`);
      const closeTimeDate = new Date(`1970-01-01T${closeTime}`);

      let currentTime = openTimeDate;
      while (currentTime <= closeTimeDate) {
        customTimes.push(
          currentTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        );
        currentTime.setMinutes(currentTime.getMinutes() + 30);
      }
    }
    setCustomTime(customTimes);
  }, [dayOfWeek, daysData]);

  const getDayOfWeekName = (dayOfWeek) => {
    const daysOfWeek = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    return daysOfWeek[dayOfWeek];
  };

  const handleChange1 = (checkedValues) => {
    const time12h = checkedValues.target.value;

    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    const time24h = `${hours}:${minutes}`;

    setTime(time24h);
  };

  const handleProps = (bookId, hairId) => {
    setBookId(bookId);
    setHairId(hairId);
  };

  const isWeekend = (date) => {
    const dayOfWeek = date.day();
    if (
      hairId === "6681e46e49b0e97bf259f390" ||
      hairId === "6682cbb70a0fa4772fa52ffa"
    ) {
      if (dayOfWeek === 6) {
        return true; 
      }
    }
    return dayOfWeek === 0 || dayOfWeek === 2 || dayOfWeek === 4;
  };

  useEffect(() => {
    dispatch(setScheduled());
  }, [dispatch]);

  useEffect(() => {
    if (upcomingDates?.length > 0) {
      setData2(upcomingDates);
    }
  }, [upcomingDates]);

  const today = dayjs();
  const minDate = today.add(1, "day");

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString?.split(":").map(Number);

    let formattedTime;

    if (hours >= 12) {
      formattedTime = format(new Date(0, 0, 0, hours, minutes), "h:mm a");
    } else {
      formattedTime = format(new Date(0, 0, 0, hours, minutes), "h:mm a");
    }

    return formattedTime;
  };

  const refurbishedDate = day && day.startsWith("0") ? day.slice(1) : day;

  function convertTo12HourFormat(timeString) {
    let [hours, minutes] = timeString
      .split(":")
      .map((num) => parseInt(num, 10));
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  const renderCheckboxWithTooltip = (time, date, handleChange1) => {
    const refinedTime = convertTo12HourFormat(date);
    const checkbox = (
      <Checkbox
        className="custom-checkbox"
        onChange={handleChange1}
        checked={time === refinedTime}
        value={time}
      />
    );

    return checkbox;
  };

  const handleDate = (newValue) => {
    const year = dayjs(newValue).format("YYYY");
    const month = dayjs(newValue).format("MM");
    const day = dayjs(newValue).format("DD");
    const dayOfWeek = dayjs(newValue).day();
    const dayOfWeekName = getDayOfWeekName(dayOfWeek);
    setDayOfWeek(dayOfWeekName);
    setDay(day);
    setMonth(month);
    setYear(year);
  };

  useEffect(() => {
    handleDate(dayjs(selectedDates[currentIndex]));
  }, [selectedDates[currentIndex]]);

  let currentDate = `${day}-${month}-${year}`;

  useEffect(() => {
    if (scheduled) {
      toast.success(`Date updated`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (cancelled) {
      toast.success(`Booking Cancelled`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [scheduled, cancelled]);

  return (
    <BookWrapper>
      <ToastContainer />
      {upcomingDates?.length > 0 && (
        <>
          {data2?.map((item, index) => {
            const handleRescheduleClick = (e) => {
              e.preventDefault();
              handleOpen(
                item?.preference?.schedule?.time,
                item?.preference?.schedule?.day,
                item?.preference?.schedule?.month,
                item?.preference?.schedule?.year,
                item?.preference?.schedule?.fullDate,
                index
              );
              handleProps(item.id, item.hairStyle.hairId);
            };
            const handleCancelClick = (e) => {
              e.preventDefault();
              setOpen2(true);
              handleProps(item.id, item.hairStyle.hairId);
            };
            return (
              <Link to={`/bookings/${item?.id}`} key={nanoid()}>
                <div className="flexWrap">
                  <div className="flex">
                    <div>
                      <div className="date">
                        <div>
                          {" "}
                          <img src={Calendar} alt="display profile" />
                        </div>
                        <div>
                          {format(
                            new Date(item?.preference?.schedule?.fullDate),
                            "iiii MMMM do, yyyy"
                          )}
                        </div>
                      </div>
                      <div className="time">
                        <div>
                          {" "}
                          <img src={Clock} alt="display profile" />
                        </div>
                        <div>
                          {formatTime(item?.preference?.schedule?.time)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="price">£{item?.totalAmount}</div>
                        <p>VAT Included</p>
                      </div>
                    </div>
                  </div>
                  <div className="actionBtn">
                    <div className="reschedule" onClick={handleRescheduleClick}>
                      Reschedule
                    </div>
                    <div className="reschedule" onClick={handleCancelClick}>
                      {cancelloader ? <Spin spinning /> : " Cancel Booking"}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </>
      )}

      {upcomingDates?.length < 1 && (
        <div className="emptyState">
          You currently do not have any Upcoming Booking
        </div>
      )}

      <CustomModal
        centered
        closable={false}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={750}
      >
        <CardBody border={"none"}>
          <div className="header">
            <h3>Reschedule Booking</h3>
            <p>Select a new time and date</p>
          </div>
          <div className="dateWrapper">
            <div className="dateBody">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  onChange={(newValue) => {
                    setTime("");
                    handleDate(newValue);
                  }}
                  defaultValue={dayjs(selectedDates[currentIndex] || null)}
                  minDate={minDate}
                  shouldDisableDate={isWeekend}
                  value={dayjs(selectedDates[currentIndex] || null)}
                  renderDay={(day, _, DayComponentProps) => {
                    return (
                      <div className="calendar-day">
                        {DayComponentProps.renderDay(day)}
                        {selectedDates[currentIndex] &&
                          day.isSame(selectedDates[currentIndex], "day") && (
                            <div className="selected-day" />
                          )}
                      </div>
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="dateBody1">
              <div className="dateData">
                {day !== null && month !== null && year !== null ? (
                  <div className="acceptedDate">
                    <div>
                      {refurbishedDate}
                      {refurbishedDate === "1"
                        ? "st"
                        : refurbishedDate === "2"
                        ? "nd"
                        : refurbishedDate === "3"
                        ? "rd"
                        : "th"}
                    </div>
                    <div>{month},</div>
                    <div>{year}</div>
                  </div>
                ) : (
                  currentDate
                )}
              </div>
              {customTime.map((ctime) => {
                return (
                  <div className="dateContent" key={nanoid()}>
                    <div> {ctime}</div>
                    {renderCheckboxWithTooltip(ctime, time, handleChange1)}
                  </div>
                );
              })}
            </div>
          </div>
        </CardBody>
        <NiyoButton
          onClick={() => {
            setOpen(false);
            handleBtn(bookId, hairId, year, month, day, time);
          }}
          borderRadius={"8px"}
          width={"160px"}
          marginL={"20px"}
          marginB={"20px"}
          disabled={time === ""}
          title={time === "" ? "Please select time and date to proceed" : null}
        >
          {loading ? <Spin spinning /> : "Update"}
        </NiyoButton>
        <div className="cancel" onClick={handleCancel}>
          <img src={closeIcon} alt="closeIcon" />
        </div>
      </CustomModal>
      <CustomModal
        centered
        closable={false}
        open={open2}
        onCancel={handleCancel2}
        footer={null}
        width={750}
      >
        <div className="headers">
          <h2>Are You Sure You Want to Cancel This Booking</h2>
        </div>
        <NiyoButton
          onClick={() => {
            setOpen2(false);
            handleBtn2(bookId);
          }}
          borderRadius={"8px"}
          width={"160px"}
          marginL={"20px"}
          marginB={"20px"}
        >
          {cancelloader ? <Spin spinning /> : "Cancel Booking"}
        </NiyoButton>
        <div className="cancel" onClick={handleCancel2}>
          <img src={closeIcon} alt="closeIcon" />
        </div>
      </CustomModal>
    </BookWrapper>
  );
};

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    background: #fafafa;
    width: 100%;
    margin: 116px 0;
  }
  .cancel {
    position: absolute;
    top: -65px;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    border-radius: 6px;
  }
  .headers {
    padding: 20px;
  }
`;

export const BookWrapper = styled.div`
  .emptyState {
    display: flex;
    justify-content: center;
    align-item: center;
    margin-top: 100px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
  }
  a {
    text-decoration: none !important;
    color: var(--generic-black, #000) !important;
  }
  margin-top: 10px;
  padding-bottom: 20px;
  .reschedule {
    border-radius: 6px;
    border: 1px solid var(--primary-900-main, #0f0f0f);
    width: fit-content;
    padding: 7px;
    margin-left: 17px;
    // font-family: Instrument Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  border-radius: 8px;

  .date,
  .time {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .price {
    color: var(--generic-black, #000) !important;
    text-align: right;
    // font-family: Stacion;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    @media ${breakpointSizes.mobile} {
      font-size: 20px;
    }
  }
  p {
    color: var(--destructive-500-main, #ef4444) !important;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .flexWrap {
    margin-bottom: 15px;
    background: var(--rdr-pink-50, #fef1fb);
    padding-bottom: 10px;
  }
  .actionBtn {
    display: flex;
  }
  .flex {
    width: 98%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
`;
export default UpcomingBook;
