import { configureStore } from '@reduxjs/toolkit'
import appointmentReducer from './reducers/appointmentSlice'
import hairstyleReducer from './reducers/hairstyleSlice'
import paymentReducer from './reducers/paymentSlice'
import dashboardReducer from './reducers/dashboardSlice'

export const store = configureStore({
  reducer: {
    appointment: appointmentReducer,
    hairstyle: hairstyleReducer,
    payment: paymentReducer,
    dashboard: dashboardReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

