import styled from 'styled-components'
import breakpointSizes from '../../constants/breakpointSizes'

export const InformationLayout = styled.div`
  .informationWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .body {
      margin: 0 auto;
      width: 70%;
      @media ${breakpointSizes.mobile} {
        margin: 0 auto;
      width: 100%;
      }
    }
    img {
      width: 100%;
      // max-width: 100%;
      // border: 3px solid red;
    }
    .notify {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
    }
  }
  .forms {
    padding-top: 20px;
    width: 50%;
    @media ${breakpointSizes.mobile} {
      padding: 0 30px;
      width: 100%;
    }
    img {
      width: 90px;
    }
  }
  .image {
    width: 50%;
    height: 100%;
    background: #FAE4F5;
    .des {
      padding-top: 130px;
      width: 70%;
      color: var(--Niyo-Heading-Text, #1a1a1a);
      font-family: Light;
      font-size: 24px;
      font-style: italic;
      text-align: center;
      font-weight: 600;
      line-height: 140%;
      margin: 0 auto;
    }
    @media ${breakpointSizes.mobile} {
      display: none;
    }
  }
  .form-success {
    h3 {
      color: #333;
      font-family: Rebond Grotesque;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
    //   line-height: 140%;
    }
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    // gap: 20px;
  }
  .form-line {
    h3 {
      color: #333;
      font-family: Rebond Grotesque;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      @media ${breakpointSizes.mobile} {
        font-size: 30px;
      }
    }
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    gap: 20px;
    // margin-bottom: 30px;
    // margin-top: 30px;
    @media ${breakpointSizes.mobile} {
      display: block;
      width: 100%;
    }
    label {
      width: 100%;
    }
    .special-label {
      display: none !important;
    }
    .form-control {
      color: #000 !important;
      height: 60px !important;
      width: 100% !important;
      margin: 4% 0 2% 0 !important;
      border: 1px solid #e5e5e5 !important;
      color: #000 !important;
      padding: 0 0 0 2% !important;
      font-size: 16px !important;
      background-color: #fff !important;
      border-radius: 4px !important;
      ::placeholder {
        font-family: Light !important;
        color: #a0a6a6 !important;
        font-size: 14px !important;
      }
    }
    @media ${breakpointSizes.mobile} {
      flex-direction: column;
      flex-wrap: wrap;
      gap: 0;
      width: 100%;
    }
  }
  @media ${breakpointSizes.mobile} {
    padding: 0px 0 30px 0;
  }
`

export const InputField = styled.input`
  color: ${({ color }) => (color ? color : '#000')};
  height: 60px !important;
  width: ${({ width }) => (width ? width : '100%')};
  margin: 4% 0 2% 0;
  border: 1px solid #e5e5e5;
  color: #000;
  padding: 0 0 0 2%;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  background-color: #fff;
  border-radius: 4px;
  ::placeholder {
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Light')};
    color: #a0a6a6;
    font-size: 14px;
  }
`
