import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";

export const Layout = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: 3.2fr 6fr;
  align-items: center;
  @media ${breakpointSizes.tablet} {
    display: block;
  }
  .niyo-logo {
    width: 90px;
    cursor: pointer;
  }
  .tabs {
    width: 100%;
    color: #2d2b2b;
    font-family: Light;
    display: flex;
    overflow-x: auto;
    width: 100%;
    height: 100px;
    white-space: nowrap;
    a {
      text-decoration: none;
      color: #2d2b2b;
    }
    ul {
      display: flex;
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;
      padding-left: 0px;
      height: 100%;
      @media ${breakpointSizes.mobile} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      @media (max-width: 470px) {
        display: flex;
        flex-direction: flex;
      }
      li {
        display: inline-flex;
        align-items: center;
        // padding: 0 1rem;
        white-space: nowrap;
        height: 100%;
        padding-right: 1rem;
        cursor: pointer;
        list-style: none;
        transition: all 0.7s;
        img {
          max-width: 100%;
          width: 100%;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        @media ${breakpointSizes.mobile} {
          justify-content: flex-start;
        }
        div:first-child {
          background-color: #ff47ce;
          padding: 3px 8px;
          width: 28px;
          height: 28px;
          margin-right: 10px;
          border-radius: 50%;
          color: #fff;
        }
      }
      li.active {
        div:first-child {
          background-color: transparent;
          border: 1px solid #ff47ce;
          color: #a4a6a7;
        }
      }
      li.done {
        color: #ff47ce;
        div:first-child {
          background-color: #f6d5f1;
          color: #ff47ce;
        }
      }
      li.dones {
        div:first-child {
          background-color: red;
          // border: 1px solid #ff47ce;
          color: #a4a6a7;
        }
      }
      @media (max-width: 768px) {
        width: 90%;
      }
    }
    @media (max-width: 769px) {
      padding: 2rem 0;
    }
  }
  @media ${breakpointSizes.mobile} {
    .tabs {
      overflow-x: auto !important;
      white-space: nowrap !important;
      width: 100%;
      height: 100%;
      overflow-y: hidden;
    
      a {
        display: inline-block;
      }
    }
  }
  
      
  .tabs::-webkit-scrollbar-track {
        display: none !important;
      }
      .tabs::-webkit-scrollbar-thumb{
        display: none !important;
      }
      .tabs::-webkit-scrollbar {
        display: none !important;
      }
`;

export const HeadingLarge = styled.h1`
  font-size: 22px;
  line-height: 24px;
  font-family: Bold;
  color: #1a1a1a;
  @media ${breakpointSizes.mobile} {
    margin-top: 30px;
  }
`;