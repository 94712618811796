import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
     

export const bookAppointment = createAsyncThunk(
  'bookAppointment',
  async ({ appointmentValues, id }, { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/booking/${id}/reserve`,
          appointmentValues
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchAllBookings = createAsyncThunk(
  'fetchAllBookings',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking/booked-dates`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const  confirmBookAppointment = createAsyncThunk(
  'confirmBookAppointment',
  async ({ appointmentDateValues, hairId }, { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/booking/verify/booking-date/${hairId}`,
          appointmentDateValues
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateAppointment = createAsyncThunk(
  'updateAppointment',
  async ({ appointmentValues, id, bookingId }, { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/booking/${id}/update-reserve/${bookingId}`,
          appointmentValues
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchBookingDiscount = createAsyncThunk(
  'bookingDiscount',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/booking/user/discount`,
          payload
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const calendar = createAsyncThunk(
  'fetchCalendar',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/calendar-daily-schedule`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

