import styled from 'styled-components';

export const CarouselWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;

  ._items_holder {
    display: flex;
    flex-direction: row;
    transition: all 0.5s;
    gap: 8px;

    ._item_holder {
      min-width: 100%;
      max-width: 100%;
    }
  }

`;
