import React, { useEffect, useState } from 'react'
import { BookingContainer } from './style'
import PickHairstyle from './PickHairstyle'
import ProgressLayout from '../../components/ProgressLayout'
import banner1 from '../../asset/prices.svg'
import bannerMobile1 from '../../asset/allPrice.svg'
import { BannerCarousel } from '../../components/banner-carousel'

const Booking = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 450px)'); // You can adjust the media query as needed

    const handleWidthChange = (e) => {
      setIsMobile(e.matches);
    };

    setIsMobile(mediaQuery.matches); // Set initial value

    mediaQuery.addListener(handleWidthChange); // Add listener for changes

    return () => {
      mediaQuery.removeListener(handleWidthChange); // Clean up listener on component unmount
    };
  }, []);

  const globalBanners = [
    // <img src={banner2} alt="banner" />,
    // <img src={banner4} alt="banner" />,
    // <img src={banner3} alt="banner" />,
    <img src={banner1} alt="banner" />,
  ]
  const globalBannersMobile = [
    <img src={bannerMobile1} alt="banner" />,
  ]
  return (
    <BookingContainer>
      <div className='progress_section'>
       <ProgressLayout />
      </div>
      <div className='banners'>
        <BannerCarousel carouselItems={ isMobile ? globalBannersMobile : globalBanners} />
      </div>
      <div className="booking_section">
        <div className="tab-content">
          <>
            <PickHairstyle />
          </>
        </div>
      </div>
    </BookingContainer>
  )
}

export default Booking