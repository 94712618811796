import { createSlice } from '@reduxjs/toolkit'
import { getBook, auth, referral, singleBook, getAuth, schedule, getDiscount, cancelBooking } from '../../api/dashboard'

const initialState = {
  loading: false,
  cancelloader: false,
  scheduled: false,
  cancelled: false,
  error: null,
  unknownError: null,
  bookingData: null,
  authUser: null,
  emailSent: false,
  refData: null,
  authData: null,
  discount: null,
  singleBookData: null,
  reload: false,
  searchedRef: '',
  searchedReferral: '',
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken')
      localStorage.removeItem('userAccess') 
      localStorage.removeItem('isFirstVisit') 
      state.loading = false
      state.error = null
      state.emailSent = false
      
    },
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedReferral = payload
    },
    setScheduled: (state) => {
      state.scheduled = false
      state.cancelled = false
    },
    sortGlobal: (state, action) => {
      const payload = action.payload
      state.bookingData = payload
    },
    sortReload: (state ) => {
      state.reload = true
    },
  },
  extraReducers: {
    [getBook.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getBook.fulfilled]: (state, action) => {
      state.loading = false
      state.bookingData = action.payload
    },
    [getBook.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getAuth.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getAuth.fulfilled]: (state, action) => {
      state.loading = false
      state.authData = action.payload
    },
    [getAuth.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [auth.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [auth.fulfilled]: (state, action) => {
      state.loading = false
      state.authUser = action.payload
      state.emailSent = true
    },
    [auth.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.data.message
      state.unknownError = action.payload.status
    },
    [getDiscount.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getDiscount.fulfilled]: (state, action) => {
      state.loading = false
      state.discount = action.payload
    },
    [getDiscount.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [singleBook.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [singleBook.fulfilled]: (state, action) => {
      state.loading = false
      state.singleBookData = action.payload
    },
    [singleBook.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [referral.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [referral.fulfilled]: (state, action) => {
      state.loading = false
      state.refData = action.payload
    },
    [referral.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [schedule.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [schedule.fulfilled]: (state, action) => {
      state.loading = false
      state.scheduled = true
    },
    [schedule.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload.data.message
      state.unknownError = action.payload.status
    },
    [cancelBooking.pending]: (state) => {
      state.cancelloader = true
      state.error = null
    },
    [cancelBooking.fulfilled]: (state, action) => {
      state.cancelloader = false
      state.cancelled = true
    },
    [cancelBooking.rejected]: (state, action) => {
      state.cancelloader = false
      state.error = action.payload.data.message
      state.unknownError = action.payload.status
    },
  },
})
export default dashboardSlice.reducer

export const { sortBySearch, sortGlobal, logout, sortReload, setScheduled } = dashboardSlice.actions
