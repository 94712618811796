import * as React from 'react'
import { Skeleton } from 'antd'
import styled from 'styled-components'
import { nanoid } from "nanoid";

export function Loader() {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <LoadWrapper>
      {arr.map(() => (
        <div key={nanoid()}>
          <div className='imgLoader'>
            <Skeleton
              paragraph={{
                rows: 0,
              }}
              style={{ width: '140px', height: '140px' }}
              active={true}
              size="default"
            />
          </div>
          <div className='detailsLoader'>
            <Skeleton
              paragraph={{
                rows: 0,
              }}
              style={{ width: '10px', height: '10px' }}
              active={true}
              size="default"
            />
          </div>
        </div>
      ))}
    </LoadWrapper>
  )
}

const LoadWrapper = styled.div`
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  display: grid;
  gap: 10%;
    grid-template-columns: repeat(4, 4fr);
  .imgLoader {
      .ant-skeleton-title {
        width: 140px !important;
        height: 140px !important;
        border-radius: 100px !important;
      }
  }
  .detailsLoader {
      .ant-skeleton-title {
        width: 70px !important;
        height: 10px !important;
        margin-left: 50% !important;
        margin-right: 50% !important;
      }
  }
`
export function Loader1() {

  return (
    <LoadWrapper1>
        <div>
          <div className='selectedImgLoader'>
            <Skeleton
              paragraph={{
                rows: 0,
              }}
              active={true}
              size="default"
            />
          </div>
        </div>
    </LoadWrapper1>
  )
}

const LoadWrapper1 = styled.div`
  width: 100%;
  .selectedImgLoader {
      .ant-skeleton-title {
        width: 100% !important;
        height: 510px !important;
        border-top-right-radius: 200px !important;
        border-top-left-radius: 200px !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        padding: 20px !important;
      }
  }
`
