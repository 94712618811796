import React, { useEffect } from 'react'
import styled from 'styled-components'
import { NiyoButton, Paragraph } from '../../../styles/StyledComponents'
import { Link } from 'react-router-dom'
import logo from '../../../asset/dashboard/logo.svg'
import { singleBook } from '../../../api/dashboard'
import { useParams } from 'react-router-dom'
import { nanoid } from "nanoid";
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BackArrrow from '../../../asset/dashboard/back.png'
import breakpointSizes from '../../../constants/breakpointSizes'

const SingleBooking = () => {
  const { singleBookData, error } = useSelector((state) => state.dashboard)
  const { id } = useParams()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    function fetchData() {
      dispatch(singleBook(id))
    }
    fetchData()
  }, [dispatch, id])

  const bookingDetailStatus = [
    {
      type: 'Date',
      text: `${dayjs(singleBookData?.createdAt).format('DD MMM YY')}`,
    },
    {
      type: 'Hairstyle Selected',
      text: `${singleBookData?.hairStyle?.name}`,
    },
    {
      type: 'Payment Type',
      text: 'Debit Card',
    },
    {
      type: 'Booking ID',
      text: `${singleBookData?.id}`,
    },
    {
      type: 'Duration',
      text: '60min',
    },
    {
      type: 'Payment',
      text: `${singleBookData?.status}`,
    },
  ]

  const getFirstLetters = (name) => {
    const words = name.split(' ')
    const firstLetters = words.map((word) => word.charAt(0).toUpperCase())
    return firstLetters.join('')
  }

  function handleClick() {
    navigate(-1)
  }
  return (
    <SingleBook>
      {error && (
        <div className="errorMsg">
          <div>
            <Paragraph>
              There seems to be an issue processing your request from the server
            </Paragraph>
            <NiyoButton onClick={() => window.location.reload(true)}>
              please reload or try again later
            </NiyoButton>
          </div>
        </div>
      )}
      {!error && (
        <div className="wrapper">
          <div className="headers">
            <div onClick={handleClick}>
              <img src={BackArrrow} alt="NHB Logo" className="niyo-logo" />
            </div>
            <Link to="/">
              <img src={logo} alt="NHB Logo" className="niyo-logo" />
            </Link>
          </div>
          <div className="view-info">
            <BookingDetails>
              <BookingClient>
                <div className="profile">
                  <p>{getFirstLetters('Homer Simpson')}</p>
                </div>
                <div className="detail">
                  <p>
                    {singleBookData?.user?.firstName}
                    {singleBookData?.user?.lastName}
                  </p>
                  <p>{singleBookData?.user?.email}</p>
                  <p>{singleBookData?.user?.phoneNumber?.number}</p>
                </div>
              </BookingClient>
              <BookingStatus>
                {bookingDetailStatus.map((item, i) => (
                  <div key={nanoid()} className="flex">
                    <p>{item.type}</p>
                    <p className={`${item.text}`}>{item.text}</p>
                  </div>
                ))}
              </BookingStatus>
            </BookingDetails>
            <BookingUnit>
              <div>
                <div className="flex">
                  <p className="item">Items</p>
                  <p>Unit Price</p>
                </div>
                <div className="flex">
                  <p>Hairstyle Price</p>
                  <p>£{singleBookData?.hairStyle?.price}</p>
                </div>
              </div>
              <div className="preference">
                <span>Preferences</span>
              </div>

              {singleBookData?.preference.preferences.map((preference) => {
                const selectedOptions = preference.options.filter(
                  (option) => option.isSelected,
                )
                if (selectedOptions.length > 0) {
                  return (
                    <div key={nanoid()} className="flex">
                      <p>
                        <span>{preference.title.replace('Select ', '')}</span> :{' '}
                        {selectedOptions.map((selectedOption) => (
                          <span key={nanoid()}>{selectedOption.optionName}</span>
                        ))}
                      </p>
                      {selectedOptions.map((selectedOption) => (
                        <p key={nanoid()}>{selectedOption.optionPrice}</p>
                      ))}
                    </div>
                  )
                }

                return null
              })}

              <div className="flex total">
                <p>Grand Total</p>
                <p>£{singleBookData?.totalAmount}</p>
              </div>
            </BookingUnit>
          </div>
        </div>
      )}
    </SingleBook>
  )
}

export const SingleBook = styled.div`
    .wrapper {
        margin: 0 auto;
        width:80%;
    }
    .headers {
        display: flex;
        gap: 30px;
        margin-top: 40px;
        div:first-child {
          cursor: pointer;
        }
    }
  .view-info {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 40px;
    @media ${breakpointSizes.mobile} {
      display: block;
    }
    margin-top: 40px;
    > div {
    //   padding: ${({ newBooking }) =>
      newBooking ? '' : '20px 32px 20px 24px'};
      flex: 1;
      border-radius: 8px;
    }
    .client {
      display: flex;
      align-items: start;
      gap: 24px;
      background-color: #fff;
      > p {
        color: #3dba49;
      }
      .profile {
        p {
          border-radius: 200px;
          background: #0d0d0d;
          padding: 10px 11px;
          color: #ffc2ef;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
    .payment {
      background-color: #1f1f1f;
      p:nth-child(1) {
        font-size: 16px;
        color: #fff;
        font-weight: 700;
      }
      p {
        color: #a8a8a8;
        margin-bottom: 8px;
      }
    }
  }
`

export const BookingDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  @media ${breakpointSizes.mobile} {
    margin-bottom: 25px;
  }
  //   gap: 40px;
  //   flex: 1;
`

export const BookingClient = styled.div`
  border-radius: 8px;
  background: var(--rdr-pink-50, #fef1fb);
  display: flex;
  align-items: start;
  gap: 24px;
  padding: 15px 32px 15px 34px;
  width: 100%;
  > p {
    color: #3dba49;
  }
  .profile {
    p {
      border-radius: 200px;
      background: #0d0d0d;
      padding: 10px 11px;
      color: #ffc2ef;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .detail {
    // flex-grow: 1;

    p {
      font-size: 16px;
      color: #191919;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }
`

export const BookingStatus = styled.div`
  border-radius: 8px;
  background: var(--neutral-50, #fafafa);
  padding: 10px 24px;

  .flex {
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    gap: 25px;
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
    p {
      font-size: 16px;
      text-transform: capitalize;
      color: #505050;
      @media ${breakpointSizes.mobile} {
        font-size: 15px;
      }
    }

    .completed {
      color: #3dba49;
      padding: 2px 10px;
      border-radius: 10px;
      font-size: 12px;
      background-color: #ecf8ed;
    }
    &.flex:last-child {
      border-bottom: none;
    }
  }
`

export const BookingUnit = styled.div`
  flex: 1;
  margin-left: 40px;
  border-radius: 8px;
  background: var(--neutral-50, #fafafa);
  padding: 16px 24px;
  @media ${breakpointSizes.mobile} {
    margin-left: 0px;
  }
  span {
    color: #505050;
  }
  .preference {
    padding: 14px 0;
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
  }
  .flex {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
    p:nth-child(1) {
      font-size: 16px;
      span:nth-child(2) {
        font-weight: 700;
      }
    }
    p:nth-child(2) {
      font-weight: 600;
    }
  }
  .flex:nth-child(1),
  .flex:nth-child(2) {
    span,
    .item {
      font-weight: 600;
    }
  }
  > div:nth-child(1) {
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
    .flex {
      border: none;
    }
    > p {
    }
  }
  > .flex:nth-child(1) {
    border: 0;
  }

  .sub-total {
    border: 0;
    padding: 8px 0;
  }
  .total {
    border-bottom: none;
    border-top: 0.5px solid rgba(158, 158, 158, 0.5);

    p:last-child {
      font-size: 18px;
    }
  }
`
export default SingleBooking
