import { createSlice } from "@reduxjs/toolkit";
import {
  bookAppointment,
  calendar,
  fetchBookingDiscount,
  updateAppointment,
  confirmBookAppointment,
  fetchAllBookings,
} from "../../api/appointment";

const initialState = {
  loading: false,
  discountLoader: false,
  error: [],
  unknownError: null,
  bookingStatus: null,
  errorClone: [],
  allBookings: [],
  discountError: null,
  daysData: null,
  discountData: null,
  success: false,
  data: null,
  state: false,
  information: false,
  preferenceData: null,
  firstName: "",
  lastName: "",
  postCode: "",
  email: "",
  discount: "",
  referral: "",
  phoneDetails: "",
  houseAddress: "",
  address: "",
  dialCode: "",
  shortCode: "",
  countryName: "",
  receiveBookingReminders: {
    checked: false,
  },
};

const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    getPreferenceData: (state, action) => {
      const payload = action.payload;
      state.preferenceData = payload;
    },
    changeStatus: (state, action) => {
      const payload = action.payload;
      state.state = payload;
    },
    setDiscount: (state, action) => {
      const payload = action.payload;
      state.discount = payload;
    },
    setReferral: (state, action) => {
      const payload = action.payload;
      state.referral = payload;
    },
    changeInformation: (state, action) => {
      const payload = action.payload;
      state.information = payload;
    },
    setDialCode: (state, action) => {
      const payload = action.payload;
      state.dialCode = payload;
    },
    setShortCode: (state, action) => {
      const payload = action.payload;
      state.shortCode = payload;
    },
    setCountryName: (state, action) => {
      const payload = action.payload;
      state.countryName = payload;
    },
    setFirstName: (state, action) => {
      const payload = action.payload;
      state.firstName = payload;
    },
    setLastName: (state, action) => {
      const payload = action.payload;
      state.lastName = payload;
    },
    setEmail: (state, action) => {
      const payload = action.payload;
      state.email = payload;
    },
    setPostCode: (state, action) => {
      const payload = action.payload;
      state.postCode = payload;
    },
    setPhoneDetails: (state, action) => {
      const payload = action.payload;
      state.phoneDetails = payload;
    },
    setHouseAddress: (state, action) => {
      const payload = action.payload;
      state.houseAddress = payload;
    },
    setAddress: (state, action) => {
      const payload = action.payload;
      state.address = payload;
    },
    setReceiveBookingReminders: (state, action) => {
      const payload = action.payload;
      state.receiveBookingReminders = payload;
    },
  },
  extraReducers: {
    [bookAppointment.pending]: (state) => {
      state.loading = true;
      state.error = [];
    },
    [bookAppointment.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      window.localStorage.setItem("Info", action.payload.id);
      state.information = true;
      state.error = null;
    },
    [bookAppointment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.data.message;
      state.unknownError = action.payload.status;
      state.errorClone = action.payload;
    },
    [confirmBookAppointment.pending]: (state) => {
      state.loading = true;
      state.error = [];
    },
    [confirmBookAppointment.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingStatus = action.payload;
      state.error = null;
    },
    [confirmBookAppointment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.data.message;
      state.unknownError = action.payload.status;
      state.errorClone = action.payload;
    },
    [updateAppointment.pending]: (state) => {
      state.loading = true;
      state.error = [];
    },
    [updateAppointment.fulfilled]: (state, action) => {
      state.loading = false;
      state.information = true;
      state.error = null;
    },
    [updateAppointment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.data.message;
      state.unknownError = action.payload.status;
      state.errorClone = action.payload;
    },
    [calendar.pending]: (state) => {
      state.loading = true;
      state.error = [];
    },
    [calendar.fulfilled]: (state, action) => {
      state.loading = false;
      state.daysData = action.payload;
      state.error = null;
    },
    [calendar.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchAllBookings.pending]: (state) => {
      state.loading = true;
      state.error = [];
    },
    [fetchAllBookings.fulfilled]: (state, action) => {
      state.loading = false;
      state.allBookings = action.payload;
      state.error = null;
    },
    [fetchAllBookings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchBookingDiscount.pending]: (state) => {
      state.discountLoader = true;
    },
    [fetchBookingDiscount.fulfilled]: (state, action) => {
      state.discountLoader = false;
      state.discountData = action.payload;
      state.discountError = "null";
    },
    [fetchBookingDiscount.rejected]: (state, action) => {
      state.discountLoader = false;
      state.discountError = action.payload;
    },
  },
});
export default appointmentSlice.reducer;

export const {
  getPreferenceData,
  setFirstName,
  setLastName,
  setEmail,
  setPostCode,
  setPhoneDetails,
  setReceiveBookingReminders,
  setHouseAddress,
  setReferral,
  setDiscount,
  setAddress,
  changeStatus,
  setCountryName,
  setShortCode,
  setDialCode,
  changeInformation,
} = appointmentSlice.actions;
