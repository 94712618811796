import { FooterContainer } from "./style";
import {
  HeadingSmall,
  SpaceMedium,
  Paragraph,
  SpaceSmall,
} from "../../styles/StyledComponents";
import { footerImages } from "../../constants";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <SpaceMedium />
        <div className="footer-columns">
          <div className="footer-column-1 footer-column">
            <img
              src={footerImages.niyoWhiteLogo}
              className="footer-niyo-logo"
              alt="Niyo logo"
            />
            <div className="innovative">
              <img
                src={footerImages.InnovativeUk}
                className="footer-niyo-innovative -uk"
                alt="Niyo innovative uk logo"
              />
            </div>

            {/* <div className="social-icons">
            <a href="https://www.linkedin.com/showcase/niyo-hair-and-beauty/">
              <div className="icon">
               
                <img
                  src={footerImages.linkedinIcon}
                  className="social-icon"
                  alt="Link to LinkedIn"
                />
                
              </div>
              </a>
              <a href="https://www.instagram.com/niyohairnbeauty/">
              <div className="icon">
            
                <img
                  src={footerImages.instagramIcon}
                  className="social-icon"
                  alt="Link to Instagram"
                />
                
              </div>
              </a>

              <a href="https://twitter.com/niyohairnbeauty">
              <div className="icon">
              
                <img
                  src={footerImages.twitterIcon}
                  className="social-icon"
                  alt="Link to Twitter"
                />
                
              </div>
              </a>
            </div> */}
            <div className="social-icons">
              <a href="https://www.linkedin.com/showcase/niyo-hair-and-beauty/">
                <div className="icon">
                  <img
                    src={footerImages.linkedinIcon}
                    className="social-icon"
                    alt="Link to LinkedIn"
                  />
                </div>
              </a>
              <a href="https://www.instagram.com/niyohairnbeauty/">
                <div className="icon">
                  <img
                    src={footerImages.instagramIcon}
                    className="social-icon"
                    alt="Link to Instagram"
                  />
                </div>
              </a>

              <a href="https://twitter.com/niyohairnbeauty">
                <div className="icon">
                  <img
                    src={footerImages.twitterIcon}
                    className="social-icon"
                    alt="Link to Twitter"
                  />
                </div>
              </a>
            </div>
          </div>

          <div className="footer-column-2 footer-column">
            <a
              href="https://www.niyohairandbeauty.com/collections/hair-tools"
              className="column-link"
            >
              Hair and Beauty Products
            </a>

            {/* <a href="" className="column-link">
              AR Virtual Hair Try-on
            </a> */}
          </div>

          <div className="footer-column-3 footer-column">
            <HeadingSmall color="#fff" fontSize="18px" margin="0 0 10px 0">
              Contact Us
            </HeadingSmall>
            <li>
              {" "}
              <a href="mailto:hello@niyo.co" className="column-link">
                hello@niyo.co
              </a>
            </li>

            <li>
              {" "}
              <a href="tel:0121 828 9210" className="column-link">
                0121 828 9210
              </a>
            </li>
          </div>

          <div className="footer-column-4 footer-column">
            <HeadingSmall color="#fff" fontSize="18px" margin="0 0 10px 0">
              Our Links
            </HeadingSmall>

            <li>
              {" "}
              <Link to="/login" className="column-link">
                Dashboard
              </Link>
            </li>

            <li>
              <a
                href="https://www.niyohairandbeauty.com/collections/hair-tools"
                className="column-link"
              >
                Shop
              </a>
            </li>
          </div>

          <div className="footer-column-5 footer-column">
            <HeadingSmall color="#fff" fontSize="18px" margin="0 0 10px 0">
              Legal
            </HeadingSmall>
            <li>
              <a
                href="https://docs.google.com/document/d/1g7oQU5b_3pLKMXqEuNOG6z2pZok4qRyD/edit"
                className="column-link"
              >
                Terms of Service
              </a>
            </li>

            <li>
              <Link
                to="https://www.niyohairandbeauty.com/policies/privacy-policy"
                className="column-link"
              >
                Privacy Policy
              </Link>
            </li>
          </div>
        </div>

        <div className="copyright-section">
          <SpaceSmall />
          <Paragraph color="#fff">
            <span>©</span> 2023 NIYO LTD. All Rights Reserved
          </Paragraph>
        </div>
      </FooterContainer>
    </>
  );
};

export default Footer;
