import React, { useEffect, useState } from 'react'
import { ChoosePreference, PreferenceContainer, BookingSummary } from './style'
import {
  HeadingSmall,
  NiyoButton,
  Paragraph,
  HeadingLarge,
  BackgroundContainer,
} from '../../styles/StyledComponents'
import { nanoid } from "nanoid";
import { HeaderContainer } from './style'
import { headerImages } from '../../constants'
import BookingLayout from '../../components/BookingLayout'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { previewAppointment } from '../../api/payment'
import ConfirmModal from './modals/confirmModal'

const Review = () => {
  const dispatch = useDispatch()

  const { paymentData } = useSelector((state) => state.payment)

  const [openModal, setOpenModal] = useState(false)


  const handleNavigate = () => {
    window.location.href = '/';
  };


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
    
  useEffect(() => {
    const id = localStorage.getItem('id')
    if (id) {
      async function run() {
        await dispatch(previewAppointment({ id }))
        setOpenModal(true)
      }
      run()
    }
  }, [dispatch])

  const closeModal = () => {
    setOpenModal(false)
  }

  const day = paymentData?.preference?.schedule?.day
  const month = paymentData?.preference?.schedule?.month - 1
  const year = paymentData?.preference?.schedule?.year
  const time = paymentData?.preference?.schedule?.time

  let date = new Date(year, month, day)
  let getMonth = date.toLocaleString('default', {
    month: 'long',
  })
  return (
    <PreferenceContainer>
      <BackgroundContainer
        flexDirection="row"
        height="100px"
        padding="12px"
        backgroundColor="#fff"
      >
        <HeaderContainer>
          <div className="header-left">
            <Link to="/">
              <img
                src={headerImages.niyoPinkLogo}
                className="niyo-logo"
                alt="Niyo's Logo"
              />
            </Link>
          </div>
          <div className="header-right">
            <Link to="/">
              <NiyoButton>Shop Now</NiyoButton>
            </Link>
          </div>
        </HeaderContainer>
      </BackgroundContainer>
      <HeadingLarge
        fontSize="30px"
        lineHeight="110%"
        fontFamily="light"
        marginTop="30px"
      >
        Review Booking Details
      </HeadingLarge>
      <BookingLayout>
        <BookingSummary>
          {paymentData && (
            <>
              <div className="services">
                <div className="service-head">
                  <HeadingSmall>Service(s)</HeadingSmall>
                  <HeadingSmall>Price</HeadingSmall>
                </div>
                <div className="service-one">
                  <Paragraph>{paymentData?.hairStyle?.name}</Paragraph>
                  <Paragraph style={{ fontSize: '14px' }}>
                    £ {paymentData?.hairStyle?.price.toFixed(2)}
                  </Paragraph>
                </div>
                {paymentData?.preference?.preferences?.map((item) => {
                  return item.options.map((option) => {
                    return (
                      <div className="service-one" key={nanoid()}>
                        <Paragraph>Preference: {option.optionName}</Paragraph>
                        <Paragraph style={{ fontSize: '14px' }}>
                          £ {option.optionPrice.toFixed(2)}
                        </Paragraph>
                      </div>
                    )
                  })
                })}

                <div className="total">
                  <Paragraph>Total(GBP)</Paragraph>
                  <Paragraph>£ {paymentData?.totalAmount.toFixed(2)}</Paragraph>
                </div>
              </div>
              <div className="chosen-time">
                <HeadingSmall>Booked Date & Time</HeadingSmall>
                <Paragraph style={{ marginBottom: '0' }}>
                  {day} {getMonth}, {year} {time}
                </Paragraph>
              </div>
            </>
          )}
        </BookingSummary>
        <div onClick={handleNavigate}>
          <NiyoButton backgroundColor="#FF99E4">Request Completed</NiyoButton>
        </div>
      </BookingLayout>
      {paymentData && (
        <ConfirmModal
          open={openModal}
          handleClose={closeModal}
          paymentData={paymentData}
        />
      )}
      <ChoosePreference></ChoosePreference>
    </PreferenceContainer>
  )
}

export default Review
