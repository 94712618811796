import React, { useState, useEffect } from 'react'
import { NiyoButton } from '../../styles/StyledComponents'
import HairLoook from '../../asset/dashboard/Afrohair.png'
import logo from '../../asset/dashboard/logo.svg'
import File from '../../asset/mail-open.svg'
import { InformationLayout, InputField } from './style'
import { Spin } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../../api/dashboard'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Dashboard = () => {
  const { loading, error, emailSent, unknownError } = useSelector(
    (state) => state?.dashboard,
  )
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (error !== null && typeof error !== 'string') {
      error &&
        error?.map((err) => {
          return toast.error(`${err?.name} ${err?.message}`, {
            position: toast.POSITION.TOP_CENTER,
          })
        })
    }
    if (typeof error === 'string') {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
    if (unknownError >= 500) {
      toast.error(`There Seem To Be An Issue.. Please Contact Admin`, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }, [error, unknownError])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const handleSubmit = () => {
    localStorage.removeItem('isFirstVisit')
    if (email === '') {
      return toast.error(`email is required`, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
    dispatch(auth({ email }))
  }

  return (
    <>
      <InformationLayout>
        <ToastContainer />
        <div className="informationWrapper">
          <div className="forms">
            <div className="body">
              <Link to="/">
                <img src={logo} alt="NHB Logo" className="niyo-logo" />
              </Link>
              {(!emailSent ) && (
                <div className="form-line">
                  <h3>Sign in</h3>
                  <label>
                    Email Address
                    <InputField
                      name="firstName"
                      value={email}
                      className=""
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Whats your email ?"
                    ></InputField>
                  </label>
                  <NiyoButton
                    width="100%"
                    height="55px"
                    fontSize="13px"
                    borderRadius="0px"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <Spin spinning /> : 'Login'}
                  </NiyoButton>
                </div>
              )}
              {emailSent && (
                <div className="form-success">
                  <img src={File} alt="NHB Logo" className="niyo-logo" />
                  <h3>Magic Link sent</h3>
                  <p>Login link sent to your email, Lick to login</p>
                  <a href={`mailto:${email}`} className="column-link">
                    <NiyoButton
                      width="100%"
                      height="55px"
                      fontSize="13px"
                      borderRadius="0px"
                    >
                      {loading ? <Spin spinning /> : 'Open Email'}
                    </NiyoButton>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="image">
            <div className="des">
              <div>
                Every Designer Needs To Be Loved And Cared For. Niyo Cares
              </div>
            </div>
            <img src={HairLoook} alt="hairlook" />
          </div>
        </div>
      </InformationLayout>
    </>
  )
}

export default Dashboard
