import React, { forwardRef } from 'react';
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import { format } from 'date-fns'
import { headerImages } from '../../../constants'
import { Link } from 'react-router-dom'
import breakpointSizes from '../../../constants/breakpointSizes'
import 'add-to-calendar-button'
import {
  NiyoButton,
  Paragraph,
  HeadingLarge,
} from '../../../styles/StyledComponents'
import Saloon from '../../../asset/salon-1.jpeg'
import CancelBtn from '../../../asset/cancel.png'

const Review = forwardRef(({
  handleClose,
  day,
  month,
  year,
  formatEndDate,
  formatCurrentDate,
  currentTime,
  time,
}, ref) => (
  <ReviewWrapper ref={ref}>
    <div>
      <div onClick={handleClose} className="cancelBtn">
        <img src={CancelBtn} alt="cancelBtn" />
      </div>
      <div className="Wrapper">
        <div className="wrapperContent">
          <div className="imgWrapper">
            <Link to="/">
              <img
                src={headerImages.niyoPinkLogo}
                className="niyo-logo"
                alt="Niyo's Logo"
              />
            </Link>
          </div>
          <div className="header">
            <HeadingLarge fontSize="24px" lineHeight="36px">
              Booking Confirmed
            </HeadingLarge>
            <HeadingLarge
              fontSize="18px"
              lineHeight="20px"
              fontWeight="600"
              fontFamily="light"
            >
              {day} {month}, {year}
            </HeadingLarge>
          </div>
          <Paragraph>
            Thank you for booking a hair appointment at Niyo Hair & Beauty! Your
            hair will love you for this love💕{' '}
          </Paragraph>
          <div className="footer">
            <Paragraph>View Email</Paragraph>
            <add-to-calendar-button
              name="Niyo Hair Booking"
              options="'Google'"
              location="World Wide Web"
              buttonStyle="round"
              hideIconButton
              hideBackground
              startDate={formatCurrentDate}
              endDate={formatEndDate}
              startTime={currentTime}
              endTime={time}
              timeZone="Europe/London"
            ></add-to-calendar-button>
          </div>
          <div className="btn">
            <NiyoButton
              width="346px"
              height="55px"
              onClick={() =>
                (window.location =
                  'https://www.niyohairandbeauty.com/collections/hair-tools')
              }
            >
              Shop
            </NiyoButton>
          </div>
        </div>
        <div className="saloonWrapper">
          <img src={Saloon} alt="saloon" />
        </div>
      </div>
    </div>
  </ReviewWrapper>
));

const ConfirmModal = ({ open, handleClose, paymentData }) => {
  const fulldate = paymentData?.preference?.schedule?.fullDate
  const time = paymentData?.preference?.schedule?.time
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()
  const hours = currentDate.getHours()
  const minutes = currentDate.getMinutes()
  const currentTime = `${hours}:${minutes}`
  const formatCurrentDate = format(new Date(), 'yyyy-MM-dd')
  const formatEndDate = format(new Date(fulldate), 'yyyy-MM-dd')

  console.log("startDate",formatCurrentDate, "endDate", formatEndDate,"startTime", currentTime)
  return (
    <Modal open={open} onClose={handleClose}>
      <Review
        handleClose={handleClose}
        paymentData={paymentData}
        day={day}
        month={month}
        year={year}
        formatCurrentDate={formatCurrentDate}
        formatEndDate={formatEndDate}
        currentTime={currentTime}
        time={time}
      />
    </Modal>
  )
}

const ReviewWrapper = styled.div`
  border-radius: 6px;
  width: 65%;
  margin: 0 auto;
  @media ${breakpointSizes.mobile} {
    width: 90%;
    border-radius: 10px !important;
  }
  margin-top: 100px;
  background: transparent;
 
  .cancelBtn {
    text-align: right;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .btn {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  .Wrapper {
    background: #fff;
    display: flex;
    @media ${breakpointSizes.mobile} {
      display: block;
      width: 100%;
    }
    .wrapperContent {
      width: 60%;
      @media ${breakpointSizes.mobile} {
        width: 100%;
        padding: 30px 25px 50px 25px;
      }
      padding: 30px 50px 50px 50px;
    }
    .imgWrapper {
      img {
        width: auto;
        height: 30px;
      }
    }
    .header,
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 21px;
    }
    .footer {
      p {
        text-decoration: underline;
      }
      .atcb-button {
        background: none !important;
        border: 52px solid red !important;
        box-shadow: none !important;
        padding: 0em !important;
      }
    }
    .saloonWrapper {
      @media ${breakpointSizes.mobile} {
        display: none;
        width: 100%;
      }
      width: 40%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`
export default ConfirmModal
