/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  PreferenceContainer,
  ChoosePreference,
  InputField,
  BookingSummary,
} from "./style";
import {
  Paragraph,
  CardBody,
  NiyoButton,
  HeadingSmall,
} from "../../styles/StyledComponents";
import { nanoid } from "nanoid";
import { Checkbox, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { Loader } from "./loaders/PreferenceLoader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useParams } from "react-router-dom";
import BookingLayout from "../../components/BookingLayout";
import ProgressLayout from "../../components/ProgressLayout";
import { useLocation, Link } from "react-router-dom";
import dayjs from "dayjs";
import ArrowUp from "../../asset/CaretUp.svg";
import ArrowDown from "../../asset/CaretDown.svg";
import {
  setHairId,
  setCatId,
  setYear,
  setMonth,
  setTime,
  setDay,
  setDateVal,
  setHairStylePreferenceData,
  setNewHairStylePreferenceData,
  setTotalPrice,
  setStage,
} from "../../globalState/reducers/hairstyleSlice";
import {
  setDiscount,
  setReferral,
} from "../../globalState/reducers/appointmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { hairstylePreference } from "../../api/hairstyle";
import { fetchAllBookings } from "../../api/appointment";

import {
  getPreferenceData,
  changeStatus,
} from "../../globalState/reducers/appointmentSlice";
import { ToastContainer, toast } from "react-toastify";

const Preference = () => {
  const { name } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    loading,
    data,
    hairId,
    date,
    day,
    year,
    month,
    hairStylePreferenceData,
    error,
    stage,
    newHairStylePreferenceData,
  } = useSelector((state) => state.hairstyle);

  const { state, daysData, discount, referral, errorClone, allBookings } =
    useSelector((state) => state?.appointment);
  const path = location.pathname;

  const navigate = useNavigate();

  const isWeekend = (date) => {
    const dayOfWeek = date.day();
    const specificBlockedDate = date.isSame("2024-07-12", "day");

    if (specificBlockedDate) {
      return true;
    }
    if (
      hairId === "6681e46e49b0e97bf259f390" ||
      hairId === "6681dc4949b0e97bf259ec10"
    ) {
      if (dayOfWeek === 6) {
        return true;
      }
    }
    return dayOfWeek === 0 || dayOfWeek === 2 || dayOfWeek === 4;
  };

  const findFirstEnabledDate = () => {
    // Start from the next day instead of today
    let date = dayjs().add(1, "day");
    // Continue checking each day until a day that's not a weekend is found
    while (isWeekend(date)) {
      date = date.add(1, "day");
    }
    return date;
  };

  const [dayOfWeek, setDayOfWeek] = useState(null);
  const [customTime, setCustomTime] = useState([]);
  const [selectedDate, setSelectedDate] = useState(findFirstEnabledDate());
  const [openCards, setOpenCards] = useState([]);

  const handleToggleCard = (index, event) => {
    event.stopPropagation();
    setOpenCards((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const requiredField =
    newHairStylePreferenceData &&
    newHairStylePreferenceData?.filter((data) => data?.validations?.isRequired);

  useEffect(() => {
    if (newHairStylePreferenceData) {
      setOpenCards(
        newHairStylePreferenceData?.map((item, index) => index === 0)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTotalPrice = () => {
    const total =
      newHairStylePreferenceData &&
      newHairStylePreferenceData?.reduce((acc, category) => {
        const categoryTotal = category.options.reduce((acc, option) => {
          return option.isSelected ? acc + option.optionPrice : acc;
        }, 0);
        return acc + categoryTotal;
      }, 0);
    dispatch(setTotalPrice(total));
  };

  // UseEffect to recalculate total price when options are selected/deselected
  useEffect(() => {
    calculateTotalPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHairStylePreferenceData]);

  useEffect(() => {
    if (errorClone === "please atleast select required preference") {
      setTimeout(() => {
        // eslint-disable-next-line array-callback-return
        requiredField?.map((field) => {
          toast.error(`${field?.title} preference is required`, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      }, 1000);
    }
  }, [errorClone]);

  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    let customTimes = [];

    const selectedDayData = daysData?.find(
      (dayData) => dayData?.day === dayOfWeek
    );
    if (selectedDayData) {
      const { openTime, closeTime } = selectedDayData;

      const openTimeDate = new Date(`1970-01-01T${openTime}`);
      const closeTimeDate = new Date(`1970-01-01T${closeTime}`);

      let currentTime = openTimeDate;
      while (currentTime <= closeTimeDate) {
        customTimes.push(formatTime(currentTime));
        currentTime.setMinutes(currentTime.getMinutes() + 30);
      }
    }
    setCustomTime(customTimes);
  }, [dayOfWeek, daysData]);

  const getDayOfWeekName = (dayOfWeek) => {
    const daysOfWeek = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    return daysOfWeek[dayOfWeek];
  };

  useEffect(() => {
    const preferId = localStorage.getItem("preferId");
    dispatch(hairstylePreference(preferId));
  }, [dispatch, name]);

  useEffect(() => {
    const currentDate = dayjs();
    let dayOfWeek = currentDate.day();

    const getNextAvailableDay = (dayIndex) => {
      for (let i = 1; i <= 7; i++) {
        const nextDayIndex = (dayIndex + i) % 7;
        const nextDayName = getDayOfWeekName(nextDayIndex);

        const dayData = daysData?.find((day) => day.day === nextDayName);
        if (dayData && !dayData.blocked) {
          return nextDayName;
        }
      }
      return null;
    };

    const nextDayName = getNextAvailableDay(dayOfWeek);
    if (nextDayName) {
      setDayOfWeek(nextDayName);
    }
  }, []);

  const handleOptionClick = (event, categoryId, Index) => {
    event.stopPropagation();
    const updatedOptions =
      newHairStylePreferenceData &&
      newHairStylePreferenceData?.map((category, index) => {
        if (index === categoryId) {
          return {
            ...category,
            options: category.options.map((option, optionIndex) => {
              if (optionIndex === Index) {
                return {
                  ...option,
                  isSelected: true,
                };
              } else {
                return {
                  ...option,
                  isSelected: false,
                };
              }
            }),
          };
        } else {
          return category;
        }
      });

    dispatch(setNewHairStylePreferenceData(updatedOptions));
  };

  const handleChange1 = (checkedValues) => {
    const time12h = checkedValues.target.value;

    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    const time24h = `${hours}:${minutes}`;

    dispatch(setTime(time24h));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!path.includes("preferenze")) {
      dispatch(setHairId(name));
      dispatch(setCatId(name));
    }
  }, [dispatch]);

  useEffect(() => {
    if (path.includes("preferenze"))
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [path]);

  const today = dayjs();
  const minDate = today.add(1, "day");

  useEffect(() => {
    if (!state || (stage === "preferences" && path.includes("preference"))) {
      const newData = [
        {
          title: "Scalp Tenderness",
          subTitle: "How tender is your scalp",
          options: [
            {
              optionName: "Sensitive",
              optionPrice: 0,
              isSelected: true,
            },
            {
              optionName: "Not Sensitive",
              optionPrice: 0,
              isSelected: false,
            },
          ],
        },
        {
          title: "Chattiness",
          subTitle:
            "Choose a suitable level of interaction with your hairstylist",
          options: [
            {
              optionName: "Chat Away",
              optionPrice: 0,
              isSelected: true,
            },
            {
              optionName: "A Tiny Bit",
              optionPrice: 0,
              isSelected: false,
            },
            {
              optionName: "No chatting at all",
              optionPrice: 0,
              isSelected: false,
            },
          ],
        },
      ];

      const refreshmentIndex =
        hairStylePreferenceData &&
        hairStylePreferenceData.findIndex(
          (item) => item.title.trim() === "Refreshment"
        );

      let filteredPre = [];
      // if (refreshmentIndex !== -1) {
      filteredPre =
        hairStylePreferenceData &&
        hairStylePreferenceData?.filter(
          (item, index) => index !== refreshmentIndex
        );
      // }
      const reorderedData =
        hairStylePreferenceData &&
        hairStylePreferenceData?.filter((item) =>
          item.title
            .replace(/\s+/g, "")
            .includes("Refreshment".replace(/\s+/g, ""))
        );
      const data = hairStylePreferenceData && [
        ...filteredPre,
        ...reorderedData,
        ...newData,
      ];
      function run() {
        dispatch(setNewHairStylePreferenceData(data));
      }
      run();
    }
  }, [hairStylePreferenceData, dispatch, state, stage, path]);

  useEffect(() => {
    if (!state || (stage === "preferences" && path.includes("preference"))) {
      async function run() {
        await dispatch(setHairStylePreferenceData(data));
      }
      run();
    }
  }, [data, dispatch, state, stage, path]);

  const handleDate = (newValue) => {
    setSelectedDate(newValue);
    setDateVal(newValue);
    const year = dayjs(newValue).format("YYYY");
    const month = dayjs(newValue).format("MM");
    const day = dayjs(newValue).format("DD");
    const dayOfWeek = dayjs(newValue).day();
    const dayOfWeekName = getDayOfWeekName(dayOfWeek);
    dispatch(setYear(year));
    dispatch(setMonth(month));
    dispatch(setDay(day));
    setDayOfWeek(dayOfWeekName);
    dispatch(setTime(""));
  };

  const refurbishedDate = day && day.startsWith("0") ? day.slice(1) : day;

  let todayDate = new Date();

  useEffect(() => {
    handleDate(selectedDate);
  }, [selectedDate]);

  let nextDate = new Date(todayDate.getTime() + 24 * 60 * 60 * 1000);

  let tomorrowDay = nextDate.getDate();
  let tomorrowMonth = nextDate.getMonth() + 1;
  let tomorrowYear = nextDate.getFullYear();

  let formattedNextDate = `${tomorrowDay
    .toString()
    .padStart(2, "0")}-${tomorrowMonth
    .toString()
    .padStart(2, "0")}-${tomorrowYear}`;

  const handleBtn1 = () => {
    dispatch(changeStatus(true));
    dispatch(fetchAllBookings());
  };
  const handleBtn2 = () => {
    dispatch(getPreferenceData(newHairStylePreferenceData));
    dispatch(setStage("information"));
  };

  const isTimePast = (time) => {
    const currentTime = new Date();
    const [hours, minutesPart] = time.split(":");
    const minutes = minutesPart.slice(0, 2);
    const amPm = minutesPart.slice(3);

    let timeHours = parseInt(hours, 10);
    const timeMinutes = parseInt(minutes, 10);

    // Convert to 24 hour format
    if (amPm === "PM" && timeHours < 12) {
      timeHours += 12;
    } else if (amPm === "AM" && timeHours === 12) {
      timeHours = 0;
    }

    const timeDate = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      timeHours,
      timeMinutes
    );

    return timeDate < currentTime;
  };

  function convertTo12HourFormat(timeString) {
    let [hours, minutes] = timeString
      .split(":")
      .map((num) => parseInt(num, 10));
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }
  const isMoreThan24HoursAhead = (selectedDate) => {
    const now = new Date();
    const selectedDateTime = new Date(selectedDate);
    const diff = selectedDateTime - now; // Difference in milliseconds
    return diff > 24 * 60 * 60 * 1000; // Check if difference is more than 24 hours
  };

  const isTimeDisabled = (time, allBookings, selectedDate) => {
    return allBookings.some((booking) => {
      const bookingDate = dayjs(booking.schedule.fullDate).format("YYYY-MM-DD");
      const bookingTime = convertTo12HourFormat(booking.schedule.time);

      if (dayjs(selectedDate).isSame(bookingDate, "day")) {
        const averageTime = parseInt(
          booking.booking.hairStyle.hairId.averageTime,
          10
        );
        const startTime = dayjs(bookingTime, "hh:mm A");
        const endTime = startTime.add(averageTime, "hours");

        const disabledStartTime = startTime.subtract(2, "hours");
        const disabledStartTime24hr = disabledStartTime.format("HH:mm");
        const endTime24hr = endTime.format("HH:mm");

        const time24hr = dayjs(time, "hh:mm A").format("HH:mm");

        return time24hr >= disabledStartTime24hr && time24hr <= endTime24hr;
      }
      return false;
    });
  };

  const renderCheckboxWithTooltip = (time, date, handleChange1) => {
    const refinedTime = convertTo12HourFormat(date);
    const isPastTime = isTimePast(time);
    const isFuture = isMoreThan24HoursAhead(selectedDate);
    const isDisabled =
      (!isFuture && isPastTime) ||
      isTimeDisabled(time, allBookings, selectedDate);
    const checkbox = (
      <Checkbox
        className="custom-checkbox"
        onChange={handleChange1}
        checked={time === refinedTime}
        value={time}
        disabled={isDisabled}
      />
    );

    return isDisabled ? (
      <Tooltip title="Can't book time, please locate available time">
        <span>{checkbox}</span>
      </Tooltip>
    ) : (
      checkbox
    );
  };
  const allOptionsFalse =
    newHairStylePreferenceData &&
    newHairStylePreferenceData?.every((optionItem) => {
      return optionItem.options.every((option) => !option.isSelected);
    });

  useEffect(() => {
    // Check if filteredData is undefined or empty, then navigate to home page
    if (allOptionsFalse && path.includes("preferenze")) {
      navigate("/");
    }
  }, [allOptionsFalse, navigate, path]);

  return (
    <PreferenceContainer>
      <ToastContainer />
      <ProgressLayout />
      <Paragraph>
        Step 2: Select personal preferences <i>(Payment is after service)</i>
      </Paragraph>

      {error && (
        <div className="errorMsg">
          <div>
            <Paragraph>
              There seems to be an issue processing your request from the server
            </Paragraph>
            <NiyoButton onClick={() => window.location.reload(true)}>
              please reload or try again later
            </NiyoButton>
          </div>
        </div>
      )}
      {!error && (
        <>
          {path.includes("preference") && (
            <>
              <BookingLayout>
                {loading && <Loader />}

                {!loading && newHairStylePreferenceData && (
                  <>
                    {newHairStylePreferenceData?.map(
                      (card, categoryId, index) => {
                        return (
                          <CardBody key={nanoid()} width={"100%"}>
                            <div>
                              <div
                                className="card_header"
                                onClick={(e) => handleToggleCard(categoryId, e)}
                              >
                                <div>
                                  <h2>{card.title}</h2>
                                  <p>{card.subTitle}</p>
                                </div>
                                <div>
                                  {openCards && (
                                    <>
                                      {openCards[categoryId] && (
                                        <img src={ArrowUp} alt="arrows" />
                                      )}
                                      {!openCards[categoryId] && (
                                        <img src={ArrowDown} alt="arrows" />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div>
                                {openCards && openCards[categoryId] && (
                                  <div
                                    className="selectorWrapper"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {card?.options?.map((item, optionIndex) => {
                                      return (
                                        <>
                                          <div
                                            key={item}
                                            className="selector"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <div>{item?.optionName}</div>
                                            <div
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <Checkbox
                                                className="custom-checkbox"
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                                onChange={(e) =>
                                                  handleOptionClick(
                                                    e,
                                                    categoryId,
                                                    optionIndex
                                                  )
                                                }
                                                checked={item.isSelected}
                                                value={item.isSelected}
                                              >
                                                <span
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  {`${
                                                    item.optionPrice
                                                      ? `£${item.optionPrice?.toFixed(
                                                          2
                                                        )}`
                                                      : item.optionPrice === 0
                                                      ? ""
                                                      : "-"
                                                  }`}
                                                </span>
                                              </Checkbox>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </CardBody>
                        );
                      }
                    )}
                    <BookingSummary>
                      <div className="policy">
                        <HeadingSmall>Promo Codes and Deals</HeadingSmall>
                        <Paragraph>
                          Input your special referral code or Discount code to
                          get discounts
                        </Paragraph>
                        <InputField
                          name="firstName"
                          value={discount}
                          onChange={(e) =>
                            dispatch(setDiscount(e.target.value))
                          }
                          className=""
                          ht={"70px"}
                          placeholder="Discount Code (optional)"
                        ></InputField>
                        <div>
                          <div>
                            <InputField
                              name="firstName"
                              value={referral}
                              onChange={(e) =>
                                dispatch(setReferral(e.target.value))
                              }
                              className=""
                              ht={"70px"}
                              rd={"0px"}
                              allborder={"6px"}
                              placeholder="Referral Code (optional)"
                            ></InputField>
                          </div>
                        </div>
                      </div>
                    </BookingSummary>
                  </>
                )}

                {path.includes("preference") && hairId && (
                  <div className="btn">
                    <Link to="/preferenze">
                      <NiyoButton onClick={handleBtn1}>Next</NiyoButton>
                    </Link>
                  </div>
                )}
              </BookingLayout>
              <ChoosePreference></ChoosePreference>
            </>
          )}
          {path.includes("preferenze") && (
            <BookingLayout>
              <CardBody width={"100%"}>
                <div className="dateWrapper">
                  <div className="dateBody">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        onChange={(newValue) => handleDate(newValue)}
                        value={selectedDate}
                        minDate={minDate}
                        shouldDisableDate={isWeekend}
                        open={true}
                        renderDay={(day, _, DayComponentProps) => {
                          return (
                            <div className="calendar-day">
                              {DayComponentProps.renderDay(day)}
                              {selectedDate &&
                                day.isSame(selectedDate, "day") && (
                                  <div className="selected-day" />
                                )}
                            </div>
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="dateBody1">
                    <div className="dateData">
                      {day !== null && month !== null && year !== null ? (
                        <div className="acceptedDate">
                          <div>
                            {refurbishedDate}
                            {refurbishedDate === "1"
                              ? "st"
                              : refurbishedDate === "2"
                              ? "nd"
                              : refurbishedDate === "3"
                              ? "rd"
                              : "th"}
                          </div>
                          <div>{month},</div>
                          <div>{year}</div>
                        </div>
                      ) : (
                        formattedNextDate
                      )}
                    </div>
                    {customTime.map((time, i) => (
                      <div className="dateContent">
                        <div>{time}</div>
                        {renderCheckboxWithTooltip(time, date, handleChange1)}
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>

              {path.includes("preferenze") &&
              (!day || !month || !year || !date) ? (
                <div style={{ pointerEvents: "none" }}>
                  <NiyoButton>Select a date and time</NiyoButton>
                </div>
              ) : (
                ""
              )}
              {path.includes("preferenze") &&
                day &&
                month &&
                year &&
                date && (
                  <div className="btn">
                    <Link to="/information">
                      <NiyoButton onClick={handleBtn2}>Proceed</NiyoButton>
                    </Link>
                  </div>
                )}
              <ChoosePreference></ChoosePreference>
            </BookingLayout>
          )}
        </>
      )}
    </PreferenceContainer>
  );
};

export default Preference;
