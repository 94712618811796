import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityLogout = ({ onLogout }) => {
  const navigate = useNavigate();
  let inactivityTimer;

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      onLogout();
    const token = localStorage.getItem('userToken')
      if (token) {
        navigate('/login');
      }
    }, 1800000 ); 
  };

  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    resetInactivityTimer();

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearTimeout(inactivityTimer);
    };
  }, [onLogout]);

  return <div>{ }</div>;
};

export default InactivityLogout;
