import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../asset/dashboard/logo.svg";
import { getBook, getAuth } from "../../../api/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { schedule, cancelBooking } from "../../../api/dashboard";
import { sortGlobal } from "../../../globalState/reducers/dashboardSlice";
import Hand from "../../../asset/dashboard/hand.png";
import Calendar from "../../../asset/dashboard/calender.png";
import Referal from "../../../asset/dashboard/Left.png";
import styled from "styled-components";
import UpcomingBook from "./upcomingBook";
import breakpointSizes from "../../../constants/breakpointSizes";
import { calendar } from "../../../api/appointment";
import PastBook from "./pastBook";
import CancelledBook from "./cancelledBook";
import { ToastContainer, toast } from "react-toastify";

const Dashboard = () => {
  const { bookingData, authData, error, unknownError, cancelled } = useSelector(
    (state) => state.dashboard
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const [status, setStatus] = useState("upcoming");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    localStorage.removeItem("userToken");
    if (!token) {
      navigate("/login");
    } else {
      localStorage.setItem("userToken", token);
    }
    function fetchData() {
      dispatch(calendar());
      dispatch(getBook());
      dispatch(getAuth());
    }

    fetchData();

    const isFirstVisit = localStorage.getItem("isFirstVisit");
    if (!isFirstVisit) {
      localStorage.setItem("isFirstVisit", "true");
      window.location.reload();
    }

    if (cancelled) {
      dispatch(getBook());
    }

    return () => {
      // Cleanup if needed
    };
  }, []);

  useEffect(() => {
    if (unknownError >= 500) {
      console.log(unknownError, "unknownError", typeof unknownError);
      setTimeout(() => {
        toast.error(
          `There Seem To Be An Issue With The Server.. Please Contact Admin Or Try Again`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }, 2000);
    }
    if (unknownError < 500 && error) {
      console.log(unknownError, "unknownError", typeof unknownError);
      setTimeout(() => {
        toast.error(`${error}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 2000);
    }
  }, [error, unknownError]);

  const handleBook = () => {
    localStorage.setItem("userAccess", "dashboard");
  };

  const getFirstLetters = (name) => {
    if (name.includes("undefined")) {
      return <p className="skeleton"></p>;
    }
    const words = name.split(" ");
    const firstLetters = words.map((word) => word.charAt(0).toUpperCase());
    return firstLetters.join("");
  };

  const handleBtn = (bookId, hairId, year, month, day, time) => {
    const updatedData = bookingData?.map((item) =>
      item.id === bookId
        ? {
            ...item,
            preference: {
              ...item.preference,
              schedule: {
                ...item.preference.schedule,
                year,
                month,
                day,
                time,
                fullDate: `${year}-${month}-${day}T${time}:00.000Z`,
              },
            },
          }
        : item
    );
    dispatch(sortGlobal(updatedData));
    const scheduled = {
      year,
      month,
      day,
      time,
      fullDate: `${year}-${month}-${day}T${time}:00.000Z`,
    };
    dispatch(schedule({ bookId, hairId, schedule: scheduled }));
  };

  const handleBtn2 = (bookId) => {
    dispatch(cancelBooking({ bookId }));
    dispatch(getBook());
  };

  const currentDate = new Date();

  // Filter out canceled and requested refund bookings
  const filteredData = bookingData?.filter((item) => {
    const itemDate = new Date(item.preference.schedule.fullDate);
    return (
      itemDate >= currentDate &&
      !["Cancelled Booking", "Requested Refund"].includes(item.status)
    );
  });

  // Count the number of canceled and requested refund bookings
  const canceledAndRefundCount = bookingData?.filter((item) =>
    ["Cancelled Booking", "Requested Refund"].includes(item.status)
  );

  // Separate upcoming and past dates
  const upcomingDates = filteredData?.map((item) => item);
  const pastDates = bookingData
    ?.filter(
      (item) =>
        !filteredData.includes(item) &&
        item.status !== "Cancelled Booking" &&
        item.status !== "Requested Refund"
    )
    .map((item) => item);

  return (
    <DashboardWrapper>
      <ToastContainer />
      <Link to="/">
        <img src={logo} alt="NHB Logo" className="niyo-logo" />
      </Link>
      <>
        <DashboardHeader>
          <Link to="/profile">
            <div>
              <p>
                <div>
                  {getFirstLetters(
                    `${authData?.firstName} ${authData?.lastName}`
                  )}
                </div>
              </p>
            </div>
          </Link>
          <div>
            <span className="welcome">Welcome {authData?.firstName}</span>
            <br />
            <span className="welcome-paragraph">Manage your booking</span>{" "}
          </div>
          <div>
            <img src={Hand} alt="welcome" />
          </div>
        </DashboardHeader>
        <Actions>
          <Link to="/">
            <div className="btn1" onClick={handleBook}>
              <div>
                {" "}
                <img src={Calendar} alt="welcome" />
              </div>
              <div>New Booking</div>
            </div>
          </Link>
          <Link to="/referral">
            <div className="btn2">
              <div>
                {" "}
                <img src={Referal} alt="welcome" />
              </div>
              <div>Referrals</div>
            </div>
          </Link>
        </Actions>
        <div className="heading">Bookings</div>
        <Tab>
          <div onClick={() => setStatus("upcoming")} className="tab-wrap">
            <div className="upcoming">
              <div className={`page ${status === "upcoming" && "active"}`}>
                Upcoming Bookings
              </div>{" "}
              <div className={`no ${status !== "upcoming" && "active1"}`}>
                {upcomingDates?.length}
              </div>
            </div>
            <div className={`${status === "upcoming" && "line"}`}>{}</div>
          </div>
          <div onClick={() => setStatus("past")} className="tab-wrap">
            <div className="past">
              <div className={`page ${status === "past" && "active"}`}>
                Past Bookings{" "}
              </div>{" "}
              <div className={`no ${status !== "past" && "active1"}`}>
                {pastDates?.length}
              </div>
            </div>
            <div className={`${status === "past" && "line2"}`}>{}</div>
          </div>
          <div onClick={() => setStatus("cancelled")} className="tab-wrap">
            <div className="past">
              <div className={`page ${status === "cancelled" && "active"}`}>
                Cancelled Bookings{" "}
              </div>{" "}
              <div className={`no ${status !== "cancelled" && "active1"}`}>
                {canceledAndRefundCount?.length}
              </div>
            </div>
            <div className={`${status === "cancelled" && "line2"}`}>{}</div>
          </div>
        </Tab>
        {status === "upcoming" && (
          <UpcomingBook
            upcomingDates={upcomingDates}
            handleBtn={handleBtn}
            handleBtn2={handleBtn2}
          />
        )}
        {status === "past" && <PastBook pastDates={pastDates} />}
        {status === "cancelled" && (
          <CancelledBook cancelledBook={canceledAndRefundCount} />
        )}
      </>
    </DashboardWrapper>
  );
};

export const Tab = styled.div`
  display: flex;
  border-bottom: 1px solid var(--neutral-200, #e5e5e5);
  width: fit-content;
  padding-bottom: 10px;
  .tab-wrap {
    position: relative;
  }
  .line {
    position: absolute;
    width: 100%;
    bottom: -11px;
    // background: 3px solid #fef1fb;
    border: 1px solid #ff63dc;
  }
  .line2 {
    position: absolute;
    width: 100%;
    bottom: -11px;
    background: 3px solid #fef1fb;
    border: 1px solid #ff63dc;
  }
  .upcoming {
    display: flex;
    align-items: center;
    cursor: pointer;
    // border-bottom: 1px solid var(--neutral-200, #e5e5e5);
  }
  .past {
    display: flex;
    margin-left: 25px;
    align-items: center;
    cursor: pointer;
    // border-bottom: 1px solid var(--neutral-200, #e5e5e5);
  }
  .active {
    color: var(--rdr-pink-50, #ff63dc) !important;
  }
  .page {
    color: var(--neutral-700, #404040);
    text-align: center;
    // font-family: Instrument Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    @media ${breakpointSizes.mobile} {
      font-size: 15px;
    }
  }
  .active1 {
    color: var(--neutral-700, #000) !important;
    background: var(--rdr-pink-50, #f5f5f5) !important;
  }
  .no {
    margin-left: 15px;
    // padding: 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    background: var(--rdr-pink-50, #fef1fb);
    color: var(--neutral-700, #404040);
    text-align: center;
    // font-family: Instrument Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    // margin-right: 10px;
    border-radius: 50%;
    color: #ff63dc;
  }
`;
export const Actions = styled.div`
  a {
    text-decoration: none;
  }
  padding-top: 20px;
  display: flex;
  gap: 20px;
  .btn1 {
    border-radius: 6px;
    background: #ff63dc;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 12px;
    color: white;
    // font-family: Instrument Sans;
    // font-size: 16px;
    // font-style: normal;
    // font-weight: 500;
    // line-height: 24px;
  }
  .btn2 {
    border-radius: 6px;
    background: #02c75a;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 12px;
    color: white;
    // font-family: Instrument Sans;
    // font-size: 16px;
    // font-style: normal;
    // font-weight: 500;
    // line-height: 24px;
  }
`;
export const DashboardWrapper = styled.div`
  a {
    text-decoration: none;
  }
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
  .heading {
    color: var(--primary-900-main, #0f0f0f);
    // font-family: Stacion;
    font-size: 24px;
    padding: 24px 0px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
  }
`;
export const DashboardHeader = styled.div`
  padding-top: 100px;
  display: flex;
  //   align-items: center;
  .skeleton {
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    border-radius: 50%;
    animation: shimmer 1s infinite;
  }
  p {
    div {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background: #0d0d0d;
      padding: 10px 11px;
      color: #ffc2ef;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  gap: 20px;
  .welcome {
    color: var(--primary-900-main, #0f0f0f);
    // font-family: Stacion;
    font-size: 40px;
    font-style: normal;
    font-weight: bolder;
    line-height: 48px;
    letter-spacing: -0.8px;
    @media ${breakpointSizes.mobile} {
      font-size: 25px;
    }
  }
  .welcome-paragraph {
    color: var(--neutral-600, #525252);
    // font-family: Instrument Sans;
    font-size: 16px;
    font-style: normal;
    @media ${breakpointSizes.mobile} {
      font-size: 15px;
    }
    font-weight: 400;
    line-height: 24px;
  }
`;
export const FilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tabs {
    & > div {
      background: inherit;
      .Mui-selected {
        background: none;
        color: #1676f3;
        border-bottom: 2px solid #1676f3;
      }
      button {
        border-radius: 0;
        color: #000000;
        font-weight: normal;
        padding: 1rem 0.5rem;
        &:hover {
          background: none;
        }
      }
    }
  }
  .search {
    display: flex;
    margin: 0 18px;
    align-items: center;
    margin-bottom: 16px;

    div {
      position: relative;
      input {
        border-radius: 15px;
        height: 20px;
        border: none;
        border: 1px solid #1676f3;
        font-size: 12px;
        padding: 5px 8px;
        outline: none;
        margin-right: 10px;
        padding-right: 18px;
        height: 100%;
      }
    }
    p {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
      line-height: 20px;
      margin: 0;
      svg {
        margin-right: 5px;
      }
      span {
        font-size: 16px;
      }
    }
    .searchToggle {
      animation: search 100ms ease-in-out;
    }
    @keyframes search {
      from {
        width: 0px;
      }
      to {
        width: 140px;
      }
    }
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: inline-block;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;

    .search {
      flex-direction: row-reverse;
      align-items: center;
      height: 35px;
      margin-left: 0;
      div {
        input {
          padding: 3px 8px;
          outline: none;
          margin-left: 5px;
        }
      }
      button {
        display: flex;
        flex-direction: row-reverse;
        outline: none;
        svg {
          margin: 0 10px;
        }
      }
      .mobile {
        display: inline-block;
      }
      .desktop {
        display: none;
      }
    }
  }
`;
export default Dashboard;
