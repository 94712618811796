import niyoPinkLogo from "../asset/header/niyo-pink-logo.png";

import niyoWhiteLogo from "../asset/footer/niyo-logo-white.png";
import linkedinIcon from "../asset/footer/linkedin-icon.png";
import InnovativeUk from "../asset/footer/innovate uk logo.svg";
import instagramIcon from "../asset/footer/instagram-icon.png";
import twitterIcon from "../asset/footer/twitter-icon.png";

import glamourLogo from "../asset/home/hero/glamour-logo.png";
import homeHero from "../asset/home/hero/hero-image.png";
import heroOrangeFlare from "../asset/home/hero/hero-orange-flare.png";
import heroPinkFlare from "../asset/home/hero/hero-pink-flare.png";
import marqueeSparkle from "../asset/home/why-niyo/marquee-sparkle.png";
import starBubble from "../asset/home/why-niyo/star-bubble.png";
import whyIcon1 from "../asset/home/why-niyo/why-icon-1.png";
import whyIcon2 from "../asset/home/why-niyo/why-icon-2.png";
import whyIcon3 from "../asset/home/why-niyo/why-icon-3.png";
import whyIcon4 from "../asset/home/why-niyo/why-icon-4.png";
import uniquePlay from "../asset/home/unique/unique-play-image.png";
import uniqueSlay from "../asset/home/unique/unique-slay-image.png";
import uniqueCare from "../asset/home/unique/unique-care-image.png";
import starBubbleBackground from "../asset/home/unique/star-bubble-background.png";
import bookAppointmentImage from "../asset/home/book-appointment/book-appointment-image.png";
import bookingLilacFlare from "../asset/home/book-appointment/booking-lilac-flare.png";
import playImage from "../asset/home/play/play-image.png";
import rentSpaceImage from "../asset/home/space/rent-space.png";

import playHero from "../asset/home/hero/play.png";
import slayHero from "../asset/home/hero/slay.png"
import careHero from "../asset/home/hero/care.png"

import niyoLogoIcon from "../asset/home/space/niyo-logo-icon.png"

//Hair Tools
import collection from "../asset/hair-tools/tool-1.png"
import crochet from "../asset/hair-tools/tool-2.png"
import stretcher from "../asset/hair-tools/spray-3.png"
import spray from "../asset/hair-tools/tool-4.png"
import steamer from "../asset/hair-tools/steamer.png"

//Hairstyles
import hairStyle1 from "../asset/hairstyles/2e40a6fc-898c-4820-8e15-c2149b12b5e7.jpeg"
import hairStyle2 from "../asset/hairstyles/1dd131b6-cbb7-4057-83ad-155b20d51870.jpeg"
import hairStyle3 from "../asset/hairstyles/0f8b1afa-8ed6-4732-8800-18978586feaa.png"
import hairStyle4 from "../asset/hairstyles/40429b20-e2df-4283-b9c4-d304a1a6a777.jpeg"
import hairStyle5 from "../asset/hairstyles/4430e080-39e4-4ca7-b233-d8e7e454d1d6.jpeg"
import hairStyle6 from "../asset/hairstyles/486dd0f3-831f-4945-b3ba-7f49d63e3e71.jpeg"
import hairStyle8 from "../asset/hairstyles/fbc1103c-7368-4e82-bc6a-5ee00cc23577.jpeg"
import hairStyle9 from "../asset/hairstyles/b6ed8301-5c00-412c-af5c-a45a3d7ac090.png"
import hairStyle10 from "../asset/hairstyles/72072b42-45af-4b94-b7a2-566d4c8b6c5a.jpeg"
import hairStyle11 from "../asset/hairstyles/74f1358a-fe1e-422b-908b-a832c415e925.jpeg"

import shopImage1 from "../asset/home/shop/shop-images-1.png";
import shopImage2 from "../asset/home/shop/shop-images-2.png";
import slTestimonialImage from "../asset/home/testimonials/sl-testimonial.png";
import amTestimonialImage from "../asset/home/testimonials/am-testimonial.png";
import instagramPost1 from "../asset/home/instagram-posts/instagram-post-1.png";
import instagramPost2 from "../asset/home/instagram-posts/instagram-post-2.png";
import instagramPost3 from "../asset/home/instagram-posts/instagram-post-3.png";
import instagramPost4 from "../asset/home/instagram-posts/instagram-post-4.png";
import instagramPost5 from "../asset/home/instagram-posts/instagram-post-5.png";
import enhanceImage from "../asset/home/enhance/enhance-image.png";

import play from "../asset/Play.svg";
import slay from "../asset/slay.svg";
import care from "../asset/care.svg";



/////////////////////// HEADER
const headerImages = {
  niyoPinkLogo,
};

/////////////////////// FOOTER
const footerImages = {
  niyoWhiteLogo,
  InnovativeUk,
  linkedinIcon,
  instagramIcon,
  twitterIcon,
};

/////////////////////// HOME

const homeImages = {
  glamourLogo,
  homeHero,
  heroOrangeFlare,
  heroPinkFlare,
  marqueeSparkle,
  starBubble,
  starBubbleBackground,
  bookAppointmentImage,
  bookingLilacFlare,
  playImage,
  rentSpaceImage,
  shopImage1,
  shopImage2,
  enhanceImage,
  niyoLogoIcon
};

const heroImageScroll = [
  {
    id: "heroSlay",
    title: "Slay",
    image: slayHero,
    description:
      "Book various hair and beauty appointments at our premium slay by Niyo studios. ",
  },
  {
    id: "heroPlay",
    title: "Play",
    image: playHero,
    description:
      "Learn hair and beauty techniques both  virtually and  physically. ",
  },
  {
    id: "heroCare",
    title: "Care",
    image: careHero,
    description:
      "Purchase innovative hair extensions, tools, products and accessories on demand.",
  },
];

const slayMarqueeItems = [
  { title: "SLAY", textColor: "#FFC2EF", icon: marqueeSparkle, id: "slay" },
  { title: "PLAY", textColor: "#FFFFFF", icon: marqueeSparkle, id: "play" },
  { title: "CARE", textColor: "#FFC2EF", icon: marqueeSparkle, id: "care" },
  { title: "SLAY", textColor: "#FFFFFF", icon: marqueeSparkle, id: "slay2" },
  { title: "PLAY", textColor: "#FFC2EF", icon: marqueeSparkle, id: "play2" },
  { title: "CARE", textColor: "#FFFFFF", icon: marqueeSparkle, id: "care2" },
];

const whyNiyoCards = [
  {
    id: "whyNiyo1",
    image: whyIcon1,
    title: "CONVENIENT",
    description:
      "From 7am till late. Easy booking via web, phone or in the ‘slay by niyo’ studio. We go to the customer’s home or they come to enjoy a premium experience at the studio or if appropriate in our metaverse environment.",
  },
  {
    id: "whyNiyo2",
    image: whyIcon2,
    title: "TECHNOLOGY DRIVEN  SERVICES & PRODUCTS",
    description:
      "We provide 20+ services and 15+ products, offering a technology-driven experience. You can easily purchase or book what you want, whenever you want, fostering a fun and immersive technology  experience",
  },
  {
    id: "whyNiyo3",
    image: whyIcon3,
    title: "CONSISTENT QUALITY",
    description:
      "Our in-house stylists undergo rigorous training, accreditation, vetting, and testing. They are insured, DBS and identity checked, and registered as self-employed. They are all trained to deliver services in the standardized 'Niyo way,' ensuring consistency in our offerings.",
  },
  {
    id: "whyNiyo4",
    image: whyIcon4,
    title: "TRANSPARENT PRICING ",
    description:
      "To fulfil our goal of economic empowerment for our stylists, we compensate our stylists fairly.      ",
  },
];

export const customTime = [
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
]

const servicesCards = [
  {
    id: "uniquePlay",
    image: uniquePlay,
    title: "Play with Niyo",
    description:
      "Learn the latest hair and beauty techniques virtually or physically.",
    buttonText: "Book Hair workshops",
    externalLink: "https://www.eventbrite.co.uk/e/learn-how-to-braid-beginner-braiding-workshops-birmingham-tickets-625174322227",
    tag: play,
  },
  {
    id: "uniqueSlay",
    image: uniqueSlay,
    title: "Slay with Niyo",
    description:
      "Book various hair and beauty appointments at our premium slay by niyo studios or mobile at your own home powered by AR technology.",
    buttonText: "Book Hair appointments",
    path:"/booking",
    tag: slay,
  },
  {
    id: "uniqueCare",
    image: uniqueCare,
    title: "Care with Niyo",
    description:
      "Purchase innovative hair extensions, tools, products and accessories on demand.",
    buttonText: "Buy Hair tools",
    externalLink: "https://www.niyohairandbeauty.com/shop",
    tag: care,
  },
];

const allProductsCarousel = [
  {
    id: "stylistBundleCare",
    image: collection,
    title: "Stylist Bundle Care",
    tagline: "Discounted",
    price: "£200",
  },
  {
    id: "crochetHairPin",
    image: crochet,
    title: "Crochet Hair Pin",
    tagline: "New",
    price: "£55",
  },
  {
    id: "hairSteamer",
    image: spray,
    title: "Hair Steamer",
    tagline: "New",
    price: "£350",
  },
  {
    id: "naturalHairSpray",
    image: stretcher,
    title: "Natural Hair Spray",
    tagline: "New",
    price: "£20",
  },
  {
    id: "stylistBundleCare",
    image: steamer,
    title: "Stylist Bundle Care",
    tagline: "Discounted",
    price: "£200",
  },
  // {
  //   id: "crochetHairPin",
  //   image: niyoLogoIcon,
  //   title: "Crochet Hair Pin",
  //   tagline: "New",
  //   price: "£55",
  // },
  // {
  //   id: "hairSteamer",
  //   image: niyoLogoIcon,
  //   title: "Hair Steamer",
  //   tagline: "New",
  //   price: "£350",
  // },
  // {
  //   id: "naturalHairSpray",
  //   image: niyoLogoIcon,
  //   title: "Natural Hair Spray",
  //   tagline: "New",
  //   price: "£20",
  // },
];

const testimonialReviews = [
  {
    id: "s-l-testimonial",
    image: slTestimonialImage,
    title: "“Niyo Hair & Hair made it easier to try a new hairstyle",
    description:
      "“Using Niyo hair & Beauty made it so easy to try on the hairstyle that  I wasn’t  sure about. I was able to see what the cut would look like on me before committing to it “",
    name: "Susan Lawrence",
  },
  {
    id: "a-m-testimonial",
    image: amTestimonialImage,
    title: "“The best place to get your braids done is at Niyo Hair & Beauty”",
    description:
      "Using Niyo hair & Beauty made it so easy to try on the hairstyle that  I wasn’t  sure about. I was able to see what the cut would look like on me before committing to it ",
    name: "Aisha Martins",
  },
  {
    id: "s-l-testimonial",
    image: slTestimonialImage,
    title: "“Niyo Hair & Hair made it easier to try a new hairstyle",
    description:
      "“Using Niyo hair & Beauty made it so easy to try on the hairstyle that  I wasn’t  sure about. I was able to see what the cut would look like on me before committing to it “",
    name: "Susan Lawrence",
  },
  {
    id: "a-m-testimonial",
    image: amTestimonialImage,
    title: "“The best place to get your braids done is at Niyo Hair & Beauty”",
    description:
      "Using Niyo hair & Beauty made it so easy to try on the hairstyle that  I wasn’t  sure about. I was able to see what the cut would look like on me before committing to it ",
    name: "Aisha Martins",
  },
];

const hairstyles = [
  {
    image:hairStyle1,
    price:200,
    time:"2 hours",
    name: 'Passion Twists'

  }, 

  {
    image:hairStyle2,
    price:250,
    time:"2 hours",
    name: 'Police Cap'

  }, 

  {
    image:hairStyle3,
    price:300,
    time:"2 hours",
    name: 'Nulocs'

  }, 
  {
    image:hairStyle4,
    price:200,
    time:"2 hours",
    name: 'Passion Twists'

  }, 

  {
    image:hairStyle5,
    price:100,
    time:"1.5 hours",
    name: 'Gypsy Locs'

  }, 
  {
    image:hairStyle6,
    price:200,
    time:"2 hours",
    name: 'Sister Locs'

  }, 
  {
    image:hairStyle8,
    price:200,
    time:"2 hours",
    name: 'Curly Locs'

  }, 
  {
    image:hairStyle9,
    price:200,
    time:"2 hours",
    name: 'Passion Twists'

  }, 

  {
    image:hairStyle10,
    price:200,
    time:"1 hours",
    name: 'Pony Tail'

  }, 
  {
    image:hairStyle11,
    price:200,
    time:"1 hours",
    name: 'Baby Locs'

  },
]

const latestInstagramPosts = [
  instagramPost1,
  instagramPost2,
  instagramPost3,
  instagramPost4,
  instagramPost5,
  instagramPost1,
  instagramPost2,
  instagramPost3,
  instagramPost4,
  instagramPost5,
];

export const CaretDown = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9997 1.16994C10.8123 0.983692 10.5589 0.87915 10.2947 0.87915C10.0305 0.87915 9.77707 0.983692 9.5897 1.16994L5.9997 4.70994L2.4597 1.16994C2.27234 0.983692 2.01889 0.87915 1.7547 0.87915C1.49052 0.87915 1.23707 0.983692 1.0497 1.16994C0.955976 1.26291 0.881582 1.37351 0.830813 1.49537C0.780044 1.61723 0.753906 1.74793 0.753906 1.87994C0.753906 2.01195 0.780044 2.14266 0.830813 2.26452C0.881582 2.38638 0.955976 2.49698 1.0497 2.58994L5.2897 6.82994C5.38267 6.92367 5.49327 6.99806 5.61513 7.04883C5.73699 7.0996 5.86769 7.12574 5.9997 7.12574C6.13172 7.12574 6.26242 7.0996 6.38428 7.04883C6.50614 6.99806 6.61674 6.92367 6.7097 6.82994L10.9997 2.58994C11.0934 2.49698 11.1678 2.38638 11.2186 2.26452C11.2694 2.14266 11.2955 2.01195 11.2955 1.87994C11.2955 1.74793 11.2694 1.61723 11.2186 1.49537C11.1678 1.37351 11.0934 1.26291 10.9997 1.16994Z"
      fill="black"
    />
  </svg>
);


///////////////////////
///////////////////////

export {
  headerImages,
  footerImages,
  homeImages,
  heroImageScroll,
  slayMarqueeItems,
  whyNiyoCards,
  servicesCards,
  allProductsCarousel,
  testimonialReviews,
  latestInstagramPosts,
  hairstyles
};


