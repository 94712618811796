import { PaymentElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { NiyoButton } from '../../../styles/StyledComponents'

export default function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    localStorage.removeItem('Info')
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsProcessing(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/review`,
      },
    })

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occured.')
    }

    setIsProcessing(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
        {message && <div id="payment-message" style={{ color: "red", fontSize: "20px", textTransform: "uppercase" }}>{message}</div>}
      <PaymentElement id="payment-element" />
      <NiyoButton
        disabled={isProcessing || !stripe || !elements}
        marginT="20px"
      >
        {isProcessing ? 'Processing ... ' : 'Pay now'}
      </NiyoButton>
    
    </form>
  )
}
