import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import Calendar from "../../../asset/dashboard/calendar.png";
import Clock from "../../../asset/dashboard/clock.png";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import breakpointSizes from "../../../constants/breakpointSizes";

const pastBook = ({ cancelledBook }) => {
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString?.split(":").map(Number);

    let formattedTime;

    if (hours >= 12) {
      formattedTime = format(new Date(0, 0, 0, hours, minutes), "h:mm a");
    } else {
      formattedTime = format(new Date(0, 0, 0, hours, minutes), "h:mm a");
    }

    return formattedTime;
  };
  return (
    <BookWrapper>
      {cancelledBook?.length > 0 && (
        <>
          {cancelledBook?.map((item) => {
            return (
              <Link to={`/bookings/${item?.id}`} key={nanoid()}>
                <div className="flexWrap">
                  <div className="flex">
                    <div>
                      <div className="date">
                        <div>
                          {" "}
                          <img src={Calendar} alt="display profile" />
                        </div>
                        <div>
                          {format(
                            new Date(item?.preference?.schedule?.fullDate),
                            "iiii MMMM do, yyyy"
                          )}
                        </div>
                      </div>
                      <div className="time">
                        <div>
                          {" "}
                          <img src={Clock} alt="display profile" />
                        </div>
                        <div>
                          {formatTime(item?.preference?.schedule?.time)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="price">£{item?.totalAmount}</div>
                        <p>VAT Included</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </>
      )}
      {cancelledBook?.length < 1 && (
        <div className="emptyState">
          You currently do not have any cancelled booking
        </div>
      )}
    </BookWrapper>
  );
};

export const BookWrapper = styled.div`
  .emptyState {
    display: flex;
    justify-content: center;
    align-item: center;
    margin-top: 100px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
  }
  a {
    text-decoration: none !important;
    color: var(--generic-black, #000) !important;
  }
  margin-top: 10px;
  padding-bottom: 20px;
  border-radius: 8px;

  .date,
  .time {
    display: flex;
    align-items: center;
    gap: 10px;
    @media ${breakpointSizes.mobile} {
      font-size: 14px;
    }
  }
  .price {
    color: var(--generic-black, #000) !important;
    text-align: right;
    // font-family: Stacion;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    @media ${breakpointSizes.mobile} {
      font-size: 20px;
    }
  }
  p {
    color: var(--destructive-500-main, #ef4444) !important;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .flexWrap {
    margin-bottom: 15px;
    background: var(--neutral-50, #fafafa);
  }
  .flex {
    width: 98%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    display: flex;

    justify-content: space-between;
  }
`;
export default pastBook;
