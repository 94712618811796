import React, { useEffect, useState, useRef } from "react";
import { PickHairContainer, CustomModal, InputField } from "./style";
import { Link } from "react-router-dom";
import defaultImg from "../../asset/avatar.jpeg";
import Info from "../../asset/Left.svg";
import slaytag from "../../asset/booking-slay.svg";
import closeIcon from "../../asset/cancel.svg";
import priceIcon from "../../asset/prize.svg";
import { Spin } from "antd";
import { nanoid } from "nanoid";
import {
  HeadingLarge,
  HeadingSmall,
  NiyoButton,
  Paragraph,
} from "../../styles/StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { catHairstyle, hairstyleCat, allHairstyles } from "../../api/hairstyle";
import { calendar, fetchBookingDiscount } from "../../api/appointment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setSelectedImage,
  setName,
  setPrice,
  setTimes,
  setHairIds,
  setPreferId,
  setUniqueHairId,
  setStage,
} from "../../globalState/reducers/hairstyleSlice";

import { changeStatus } from "../../globalState/reducers/appointmentSlice";

import { Loader, Loader1 } from "./loaders/HairstyleLoader";

const PickHairstyle = () => {
  const {
    loading,
    catData,
    hairData,
    allHair,
    error,
    selectedImage,
    name,
    price,
    time,
    hairIds,
    uniqueHairId,
  } = useSelector((state) => state.hairstyle);

  const { discountLoader, discountError } = useSelector(
    (state) => state.appointment
  );

  const dispatch = useDispatch();

  const [uniqueDat, setUniqueDat] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [hairstyleName, setHairstyleName] = useState("All Hairstyle");
  const [selectedValue, setSelectedValue] = useState("all");
  const [open, setOpen] = useState(false);

  const topRef = useRef(null);

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCatChange = (value, name) => {
    if (value === "all") {
      dispatch(allHairstyles());
    }
    setHairstyleName(name);
    setSelectedValue(value);
    dispatch(setSelectedImage(null));
    dispatch(setName(null));
    dispatch(setPrice(null));
    dispatch(setTimes(null));
    if (value !== "all") {
      dispatch(setHairIds(value));
    }
  };

  useEffect(() => {
    if (discountError === "null") {
      setOpen(false);
      return toast.success(
        "Discount code processed, check your email for more information",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }

    if (discountError !== null && typeof discountError !== "string") {
      discountError &&
        discountError?.map((err) => {
          return toast.error(
            `${err?.name} is required or check ${err?.name} field `,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    }
  }, [discountError]);

  useEffect(() => {
    if (selectedValue === "all") {
      setUniqueDat(allHair);
      allHair &&
        localStorage.setItem(
          "hairPrice",
          price?.toFixed(2) || allHair[0]?.price?.toFixed(2)
        );
    } else {
      setUniqueDat(hairData);
      hairData &&
        localStorage.setItem(
          "hairPrice",
          price?.toFixed(2) || hairData[0]?.price?.toFixed(2)
        );
    }
  }, [allHair, dispatch, hairData, price, selectedValue, uniqueDat]);

  useEffect(() => {
    if (hairIds) {
      dispatch(catHairstyle(hairIds));
    }
  }, [hairIds, dispatch, catData]);

  const handleImageSelect = (image, name, price, time, id) => {
    localStorage.setItem("hairPrice", price?.toFixed(2));
    dispatch(setUniqueHairId(id));
    dispatch(setSelectedImage(image));
    dispatch(setName(name));
    dispatch(setPrice(price));
    dispatch(setTimes(time));
  };
  useEffect(() => {
    if (catData) {
      dispatch(setHairIds(catData[0]?.id));
    }
  }, [catData, dispatch]);

  useEffect(() => {
    if (allHair) {
      if (!price) {
        dispatch(setUniqueHairId(allHair[0]?.id));
      }
    }
  }, [allHair, dispatch, price]);

  useEffect(() => {
    function fetchData() {
      dispatch(allHairstyles());
      dispatch(hairstyleCat());
      dispatch(calendar());
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem("hasSeenModal");
    if (!hasSeenModal) {
      setTimeout(() => {
        setOpen(true);
      }, 1500);
    }
  }, []);

  const handleDiscountBtn = () => {
    if (email === "" || firstName === "" || lastName === "") {
      return toast.error("Please Fill All Fields To Get a Discount", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    const payload = {
      email,
      firstName,
      lastName,
    };
    dispatch(fetchBookingDiscount({ payload }));
    localStorage.setItem("hasSeenModal", "true");
  };
  return (
    <PickHairContainer>
      <ToastContainer />

      <div className="hair-style-container">
        {error && (
          <div className="errorMsg">
            <div>
              <Paragraph>
                There seems to be an issue processing your request from the
                server
              </Paragraph>
              <NiyoButton onClick={() => window.location.reload(true)}>
                please reload or try again later
              </NiyoButton>
            </div>
          </div>
        )}

        {!error && (
          <>
            <div className="show-hairstyle" ref={topRef}>
              <HeadingLarge>Book Hair Appointment</HeadingLarge>
              <Paragraph>
                Step 1: Pick a hairstyle that suits you{" "}
                <i>(Payment is after service)</i>
              </Paragraph>
              {loading && <Loader1 />}
              {!loading && uniqueDat && (
                <div className="show-hairstyle-frame">
                  <div
                    className="show-hair-picture"
                    style={{ backgroundImage: `${selectedImage}` }}
                  >
                    <img
                      src={
                        selectedImage ||
                        uniqueDat[0]?.thumbnail?.url ||
                        defaultImg
                      }
                      className="selected-image"
                      alt="Selected"
                    />
                    <img src={slaytag} className="slay-tag" alt="Slay tag" />
                  </div>
                  <div className="show-hair-info">
                    <div className="hair-text-info">
                      <HeadingSmall>{name || uniqueDat[0]?.name}</HeadingSmall>
                      <Paragraph fontSize="14px">
                        {time || uniqueDat[0]?.averageTime} hrs | £
                        {price?.toFixed(2) || uniqueDat[0]?.price?.toFixed(2)}
                      </Paragraph>
                    </div>
                    <div className="hair_extension">
                      <div>
                        <div>
                          <img src={Info} alt="info" />
                        </div>
                        <div className="prices">
                          All prices are inclusive of hair extensions
                        </div>
                      </div>
                    </div>
                    {uniqueDat?.length === 0 && (
                      <NiyoButton
                        backgroundColor="gray"
                        width="100%"
                        height="50px"
                      >
                        {" "}
                        Select this Hairstyle{" "}
                      </NiyoButton>
                    )}
                    {uniqueDat?.length > 0 && (
                      <Link
                        to={
                          `/preference/${uniqueHairId}` ||
                          `/preference/${uniqueHairId}`
                        }
                      >
                        <NiyoButton
                          backgroundColor="#FF47CE"
                          width="100%"
                          height="50px"
                          onClick={() => {
                            dispatch(setPreferId(uniqueHairId));
                            dispatch(setStage("preference"));
                            dispatch(changeStatus(false));
                            localStorage.removeItem("Info");
                          }}
                        >
                          {" "}
                          Select this Hairstyle{" "}
                        </NiyoButton>
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="hairstyle-list">
              <HeadingLarge>Select Hair Category</HeadingLarge>
              <div className="hairstyle-content">
                <div className="categoryWrap">
                  <div
                    className={selectedValue === "all" ? "selected" : "catItem"}
                    onClick={() => handleCatChange("all", "All Hairstyle")}
                  >
                    All Hairstyles
                  </div>
                  {catData?.map((cat) => (
                    <div
                      key={nanoid()}
                      onClick={() => handleCatChange(cat?.id, cat?.name)}
                      className={
                        selectedValue === `${cat?.id}` ? "selected" : "catItem"
                      }
                    >
                      {cat?.name}
                    </div>
                  ))}
                </div>
              </div>{" "}
              <br />
              <Paragraph color="#535353">
                Choose a Hairstyle From {hairstyleName}.
              </Paragraph>
              <div className="hair-list">
                {loading && <Loader />}
                {!loading &&
                  uniqueDat &&
                  uniqueDat?.map((item) => (
                    <div className="each-hair-container" key={nanoid()}>
                      <div
                        className="each-hair"
                        onClick={() => {
                          handleImageSelect(
                            item?.thumbnail?.url,
                            item.name,
                            item.price,
                            item.averageTime,
                            item.id
                          );
                          scrollToTop();
                        }}
                      >
                        <img
                          key={nanoid()}
                          src={item?.thumbnail?.url}
                          className={`${
                            uniqueHairId === item?.id ? "image-active" : ""
                          }`}
                          alt="Hair styles"
                        />
                        <HeadingSmall style={{ textAlign: "center" }}>
                          {item.name}
                        </HeadingSmall>
                      </div>
                    </div>
                  ))}
                {uniqueDat?.length === 0 && !loading && (
                  <div> Currently No Hairstyle For Category</div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <CustomModal
        centered
        closable={false}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={580}
      >
        <div className="modalWrapper">
          <div>
            <div className="headers">
              <div>
                <img src={priceIcon} alt="closeIcon" />
              </div>
              <div className="cancel" onClick={handleCancel}>
                <img src={closeIcon} alt="closeIcon" />
              </div>
            </div>
          </div>
          <div className="discount">
            <p>GET 10% OFF</p>
            <p>Fill the form to get discount</p>
          </div>
          <div className="forms">
            <label>
              First Name
              <InputField
                name="name"
                ht={"40px"}
                width={"100%"}
                margin={"0 0 30px 0"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className=""
                placeholder="Enter your First Name"
              ></InputField>
            </label>
            <label>
              Last Name
              <InputField
                name="email"
                width={"100%"}
                ht={"40px"}
                margin={"0 0 30px 0"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className=""
                placeholder="Enter your Last Name"
              ></InputField>
            </label>
          </div>
          <div>
            <label>
              Email
              <InputField
                name="email"
                ht={"40px"}
                margin={"0 0 30px 0"}
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className=""
                placeholder="jamessmith@gmail.com"
              ></InputField>
            </label>
          </div>
          <div className="btn">
            <div>
              <NiyoButton
                height={"40px"}
                fontFamily={"Medium"}
                margin={"auto"}
                onClick={handleDiscountBtn}
                width={"120px"}
                borderRadius={"6px"}
                disabled={discountLoader}
              >
                {discountLoader ? <Spin spinning /> : "GET IT NOW"}
              </NiyoButton>
            </div>
          </div>
        </div>
      </CustomModal>
    </PickHairContainer>
  );
};

export default PickHairstyle;
