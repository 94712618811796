import { createSlice } from '@reduxjs/toolkit'
import { previewAppointment, payment, createIntent, confirmBooking } from '../../api/payment'

const initialState = {
  loading: false,
  error: null,
  paymentData: false,
  purchaseData: null,
  secretKey: null,
  hairId: '',
}

const hairstyleSlice = createSlice({
  name: 'previewAppointment',
  initialState,
  reducers: {
    setHairId: (state, action) => {
      const payload = action.payload
      state.hairId = payload
    },
  },
  extraReducers: {
    [previewAppointment.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [previewAppointment.fulfilled]: (state, action) => {
      state.loading = false
      state.paymentData = action.payload
    },
    [previewAppointment.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [payment.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [payment.fulfilled]: (state, action) => {
      state.loading = false
      state.purchaseData = action.payload
    },
    [payment.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [confirmBooking.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [confirmBooking.fulfilled]: (state, action) => {
      state.loading = false
    },
    [confirmBooking.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [createIntent.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [createIntent.fulfilled]: (state, action) => {
      state.loading = false
      state.secretKey = action.payload
    },
    [createIntent.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
export default hairstyleSlice.reducer

export const {
  setHairId,
} = hairstyleSlice.actions
