import React from 'react'
import Footer from '../../components/Footer'

const AppLayout = ({ children }) => {
  return (
    <div>
      {children}
      <Footer />
    </div>
  )
}

export default AppLayout
