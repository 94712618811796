import React, { useEffect, useState } from "react";
import { ChoosePreference, PreferenceContainer, BookingSummary } from "./style";
import {
  HeadingSmall,
  NiyoButton,
  Paragraph,
  HeadingLarge,
} from "../../styles/StyledComponents";
import { nanoid } from "nanoid";
import { ToastContainer, toast } from "react-toastify";
import BookingLayout from "../../components/BookingLayout";
import ProgressLayout from "../../components/ProgressLayout";
import { changeInformation } from "../../globalState/reducers/appointmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { previewAppointment } from "../../api/payment";
import { Link, useNavigate } from "react-router-dom";
import PaymentModal from "./modals/paymentModal";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { paymentData, purchaseData, error } = useSelector(
    (state) => state.payment
  );

  const { data } = useSelector((state) => state.appointment);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        toast.error(
          `There Seem To Be An Issue With Processing Your Request.. Please Try Again Later`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }, 2000);
    }
  }, [error]);

  useEffect(() => {
    dispatch(changeInformation(false));
  }, [dispatch]);

  useEffect(() => {
    const id = data?.id;
    localStorage.setItem("id", id);
    function run() {
      dispatch(previewAppointment({ id }));
    }
    run();
  }, [dispatch, data]);

  useEffect(() => {
    if (purchaseData) {
      const { url } = purchaseData;
      window.location = url;
    }
  }, [navigate, purchaseData]);

  const submit = () => {
    setIsLoading(true);
    setOpenModal(true);
    setIsLoading(false);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const day = paymentData?.preference?.schedule?.day;
  const month = paymentData?.preference?.schedule?.month - 1;
  const year = paymentData?.preference?.schedule?.year;
  const time = paymentData?.preference?.schedule?.time;

  let date = new Date(year, month, day);
  let getMonth = date.toLocaleString("en-US", {
    month: "long",
  });
  return (
    <PreferenceContainer>
      <ToastContainer />
      <ProgressLayout />
      <HeadingLarge
        fontSize="30px"
        lineHeight="110%"
        fontFamily="light"
        marginTop="30px"
      >
        Confirm and Book
      </HeadingLarge>

      {error && (
        <div className="errorMsg">
          <div>
            <Paragraph>
              There seems to be an issue processing your request from the server
            </Paragraph>
            <NiyoButton onClick={() => navigate("/")}>
              Click here to reprocess
            </NiyoButton>
          </div>
        </div>
      )}
      {!error && (
        <>
          <BookingLayout>
            <BookingSummary>
              {paymentData && (
                <>
                  <div className="services">
                    <div className="service-head">
                      <HeadingSmall>Service(s)</HeadingSmall>
                      <HeadingSmall>Price</HeadingSmall>
                    </div>
                    <div className="service-one">
                      <Paragraph>{paymentData?.hairStyle?.name}</Paragraph>
                      <Paragraph style={{ fontSize: "14px" }}>
                        £ {paymentData?.hairStyle?.price?.toFixed(2)}
                      </Paragraph>
                    </div>
                    {paymentData?.preference?.preferences?.map((item) => {
                      return item.options.map((option) => {
                        return (
                          <div className="service-one" key={nanoid()}>
                            <Paragraph>
                              Preference: {option.optionName}
                            </Paragraph>
                            <Paragraph style={{ fontSize: "14px" }}>
                              £ {option.optionPrice?.toFixed(2)}
                            </Paragraph>
                          </div>
                        );
                      });
                    })}

                    <div className="total">
                      <Paragraph>Total(GBP)</Paragraph>
                      <Paragraph>
                        £ {paymentData?.totalAmount?.toFixed(2)}
                      </Paragraph>
                    </div>
                  </div>
                  <div className="chosen-time booked-time">
                    <div>
                      <HeadingSmall>Booked Date & Time</HeadingSmall>
                      <Paragraph style={{ marginBottom: "0" }}>
                        {day} {getMonth}, {year} {time}
                      </Paragraph>
                    </div>
                    <div>
                      <Link to="/preferenze">
                        {" "}
                        <Paragraph>Edit</Paragraph>{" "}
                      </Link>
                    </div>
                  </div>
                </>
              )}
              <div className="policy">
                <HeadingSmall>Cancellation Policy</HeadingSmall>
                <Paragraph>
                  Free cancellation 48 hours before {day} {getMonth}, {year}{" "}
                  {time}.{" "}
                  <a
                    style={{ color: "#f13dd4" }}
                    href="https://docs.google.com/document/d/1g7oQU5b_3pLKMXqEuNOG6z2pZok4qRyD/edit#heading=h.kua2rmi8opn"
                  >
                    Learn more
                  </a>
                </Paragraph>
                <HeadingSmall>Payment Policy</HeadingSmall>
                <Paragraph>
                  To book, you are required to pay 20% of total payment .{" "}
                </Paragraph>
                <div className="payment_data">
                  <div className="">
                    <HeadingSmall>Total Payment</HeadingSmall>
                    <HeadingSmall style={{ fontSize: "14px" }}>
                      £ {paymentData?.totalAmount?.toFixed(2)}
                    </HeadingSmall>
                  </div>
                  <div className="">
                    <HeadingSmall>Initial Payment</HeadingSmall>
                    <HeadingSmall style={{ fontSize: "14px" }}>
                      £ {data?.paymentInfo?.amount?.toFixed(2)}
                    </HeadingSmall>
                  </div>
                </div>
                <span>
                  By clicking the button below, I agree to{" "}
                  <b>
                    Niyo Hair and Beauty Ground Rules ,Policy & Cancellation
                    Policy, Payment Policy
                  </b>{" "}
                  and that Niyo Hair and beauty can charge my payment method for
                  the initial amount above
                </span>
              </div>

              <div className="btn">
                <NiyoButton
                  onClick={submit}
                  width="70%"
                  height="55px"
                  disabled={isLoading}
                >
                  Book Now
                </NiyoButton>
              </div>
            </BookingSummary>
          </BookingLayout>
          <PaymentModal
            open={openModal}
            handleClose={closeModal}
            paymentData={paymentData}
            id={data?.id}
          />
          <ChoosePreference></ChoosePreference>
        </>
      )}
    </PreferenceContainer>
  );
};

export default Payment;
