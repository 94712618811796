import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { NiyoButton, Paragraph } from '../../../styles/StyledComponents'
import { getAuth, referral } from '../../../api/dashboard'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../globalState/reducers/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../../asset/dashboard/logo.svg'
import referrals from '../../../asset/dashboard/ref.svg'
import pic from '../../../asset/dashboard/pic.svg'
import mail from '../../../asset/dashboard/mail.svg'
import phone from '../../../asset/dashboard/phone.svg'
import breakpointSizes from '../../../constants/breakpointSizes'
import BackArrrow from '../../../asset/dashboard/back.png'

const Profile = () => {
  const { authData, refData, error } = useSelector((state) => state.dashboard)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      function fetchData() {
        dispatch(getAuth())
        dispatch(referral())
      }
      fetchData()
    }, 1000)
  }, [dispatch])

  function handleClick() {
    navigate(-1)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const getFirstLetters = (name) => {
    const words = name.split(' ')
    const firstLetters = words.map((word) => word.charAt(0).toUpperCase())
    return firstLetters.join('')
  }

  return (
    <SpecificUserStyle>
      {error && (
        <div className="errorMsg">
          <div>
            <Paragraph>
              There seems to be an issue processing your request from the server
            </Paragraph>
            <NiyoButton onClick={() => window.location.reload(true)}>
              please reload or try again later
            </NiyoButton>
          </div>
        </div>
      )}
      {!error && (
        <div className="wrapper">
          <div className="headers">
            <div onClick={handleClick}>
              <img src={BackArrrow} alt="NHB Logo" className="niyo-logo" />
            </div>
            <Link to="/">
              <img src={logo} alt="NHB Logo" className="niyo-logo" />
            </Link>
          </div>
          <div>
            <div className="avatar">
              {getFirstLetters(`${authData?.firstName} ${authData?.lastName}`)}
            </div>
            <div className="content">
              <div className="name">
                {authData?.firstName} {authData?.lastName}
              </div>
              <Link to="/">
                <div
                  className="page"
                  onClick={() => {
                    dispatch(logout())
                    navigate('/login')
                  }}
                >
                  <p>Logout</p>
                </div>
              </Link>
            </div>
            <div className="content">
              <div>
                {' '}
                <img src={mail} alt="email" className="email" />
              </div>
              <div>{authData?.email}</div>
            </div>
            <div className="content">
              <div>
                <img src={phone} alt="email" className="email" />
              </div>
              <div>{authData?.phoneNumber?.number}</div>
            </div>
            <div className="content">
              <div>
                <img src={pic} alt="email" className="email" />
              </div>
              <div>Referrals</div>
              <div>{refData?.referred?.length}</div>
            </div>
            <div className="content">
              <div>
                <img src={referrals} alt="email" className="email" />
              </div>
              <div>Discount Used</div>
              <div>{refData?.program?.discount || '---'}</div>
            </div>
          </div>
        </div>
      )}
    </SpecificUserStyle>
  )
}

export default Profile
export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-input {
    width: 45%;
    position: relative;
    input {
      border: 1px solid #e3e6e9;
      padding: 16px;
      padding-left: 45px;
      width: 100%;
      font-size: 14px;
      background-color: transparent;
      border-radius: 8px;
      &::placeholder {
        font-size: 14px;
        color: #4e5054;
      }
    }
    img {
      position: absolute;
      left: 15px;
      top: 15px;
    }
  }
  .tableHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 14px;
      color: #858585;
    }
    .pagination {
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      margin-right: 16px;
      span {
        color: #858585;
        font-size: 12px;
      }
      .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
        padding: 0;
      }
      .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
        cursor: pointer;
        border: 1px solid rgba(230, 232, 234, 0.62);
        margin: 0;
        padding: 0;
        background: #fff;
        color: #858585;
        svg {
          margin-right: 5px;
          font-size: 13px;
        }
        .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
          padding: 8.5px 12px;
          background: #fff;
          margin-right: 14px;
        }
      }
    }
    .menu {
      margin: 0 28px 0 20px;
      cursor: pointer;
    }
    .grid-view {
      cursor: pointer;
      border-radius: 4px;
      padding: 12px;
    }
    .active {
      background-color: #f5f9ff;
    }
  }
`

export const SpecificUserStyle = styled.section`
a {
    text-decoration: none;
  }
.content {
    display: flex;
    align-item: center;
    gap: 20px;
    padding-bottom: 10px;
}
    color: var(--neutral-500, #737373);
    // font-family: Instrument Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
.name {
    color: var(--primary-900-main, #0F0F0F);
    // font-family: Stacion;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 120% */
    letter-spacing: -0.8px;
}
.page {
    color: #fff;
    border-radius: 6px;
    text-align: center;
    padding: 0px 20px 0px 20px;
    // font-family: Instrument Sans;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-item: center;

    background: #0d0d0d;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    @media ${breakpointSizes.mobile} {
      font-size: 15px;
    }
  }
  .street {
    color: var(--neutral-500, #737373);
    // font-family: Instrument Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 24px; 
  }
.avatar {
    margin-top: 100px;
    display: flex;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background: #0d0d0d;
    justify-content:center;
    align-items: center;
    color: #ffc2ef;
    font-size: 34px;
    font-weight: 700;
}
  .headers {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    div:first-child {
      cursor: pointer;
    }
  }
  .wrapper {
    width: 70%;
    margin: 0 auto;
    @media ${breakpointSizes.mobile} {
      width: 90%;
    margin: 0 auto;
    }
  }
  .initialSort .css-levciy-MuiTablePagination-displayedRows {
    border: 3px solid red !important;
    display: none;
  }
  .initialSort
    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
    .MuiTablePagination-actions {
    display: none;
  }
  .footer .css-pdct74-MuiTablePagination-selectLabel {
    display: none;
  }
  .footer .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    display: none;
  }
  // this is for the next bar
  .footer
    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
    .MuiTablePagination-actions {
    background: black;
    display: flex !important;
    width: 15%;
    height: 60%;
    justify-content: flex-start;
    color: white;
  }
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    color: white !important;
    background: black !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    position: none !important;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    text-align: center;
    flex: none !important;
    font-size: 1.5rem;
    padding: 8px !important;
    border-radius: 0% !important;
    overflow: hidden !important;
  }
  .ant-popover {
    div {
      > div {
        padding: 0 !important;
      }
    }
  }
  .disabled {
    pointer-events: none;
    background-color: #a7a7a7;
    &.preference {
      background-color: inherit;
    }
  }
  .footer .css-jtlhu6-MuiTablePagination-root:last-child {
    // max-width: 100%  !important;
    display: flex !important;
    position: absolute !important;
    right: 0 !important;
    width: 400px !important;
    justify-content: center;
    // border: 3px solid red;
  }
  .footer .css-jtlhu6-MuiTablePagination-root {
    display: flex !important;
    width: 100% !important;
    overflow: hidden !important;
  }
  .footer .css-1psng7p-MuiTablePagination-spacer {
    display: flex !important;
    padding-left: -230px !important;
  }
  .footer .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex !important;
    margin: 36px 0 24px;
  }
  .user-stat {
    margin: 48px 0 32px;
    display: flex;
    gap: 40px;
    div:first-child {
      margin-right: 30px;
    }
    .request {
      display: flex;
      cursor: pointer;
      align-items: center;
      // gap: 10px;
      border-radius: 6px;
      border: 1px solid var(--primary-900-main, #0F0F0F);
      padding: 8px;
    }
    h1 {
      font-size: 48px;
      font-style: normal;
      margin-left: 1px;
      margin-top: 4px;
      font-weight: 600;
      // line-height: 140%; 
    }
    .discountHeader {
      display: flex;
      align-items: center;
      // gap: 20px;
    }
    @media ${breakpointSizes.mobile} {
      display: block;
      img {
        width: 30px;
        height: 30px;
      }
    }
    // > div {
    //   background-color: #fff;
    //   border-radius: 16px;
    //   padding: 25px 15px 0px 0px;
    //   display: flex;
    //   //   gap: 24px;
    //   color: #505050;
    //   white-space: nowrap;
    //   h3 {
    //     color: #505050;
    //     font-size: 24px;
    //     font-weight: 400;
    //     @media ${breakpointSizes.mobile} {
    //       font-size: 18px;
    //     }
    //   }
    //   h1 {
    //     font-size: 48px;
    //     font-weight: 600;
    //     margin-top:-10px;
    //     @media ${breakpointSizes.mobile} {
    //       font-size: 18px;
    //     }
    //   }
    //   p {
    //     font-size: 14px;
    //   }
    //   .street {
    //     margin: 10px 0 0;
    //   }
    //   .flex {
    //     margin-bottom: 15px;
    //     gap: 24px;
    //     > div {
    //       display: flex;
    //       align-items: center;
    //       margin-top: 8px;
    //       gap: 8px;
    //       span {
    //         font-size: 12px;
    //       }
    //     }
    //   }
    //   .copy {
    //     display: flex;
    //     align-items: center;
    //     gap: 8px;
    //     margin-top: 5px;
    //   }
    // }
    // > div:nth-child(1) {
    //   width: 47%;
    //   @media ${breakpointSizes.mobile} {
    //     margin-bottom: 20px;
    //   }

    //   h1 {
    //     border-radius: 200px;
    //     background: #0d0d0d;
    //     padding: 19px 14px 19px 14px;
    //     color: #ffc2ef;
    //     font-size: 32px;
    //     font-weight: 700;
    //   }
    //   h3 {
    //     color: #0f0e0e;
    //   }
    //   h5 {
    //     color: #000;
    //     font-size: 14px;
    //     font-weight: 400;
    //   }
    //   p,
    //   span {
    //     font-family: reboundLight;
    //     color: #606060;
    //   }
    // }
    // > div:nth-child(2) {
    //   width: 27%;
    // }
    // > div:nth-child(3) {
    //   //   width: 27%;
    // }
    // > div:nth-child(2),
    // > div:nth-child(3) {
    //   display: flex;
    //   //   gap: 22px;
    //   flex-direction: column;
    //   padding: 24px 15px 0 24px;
    //   @media ${breakpointSizes.mobile} {
    //     padding: 0px;
    //     gap: -10px;
    //   }
    //   div {
    //     display: flex;
    //     align-items: center;
    //     gap: 12px;
    //     h3 {
    //       font-family: rebound;
    //       color: #191919;
    //     }
    //   }
    //   h1 {
    //     // margin-left: 55px;
    //     color: #505050;
    //   }
    // }
  }
  .heading {
    margin-bottom: 24px;
    h3 {
      color: #2c2c2c;
    }
  }
`
