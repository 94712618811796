import styled from "styled-components";
import breakpointSizes from "../constants/breakpointSizes";

export const BackgroundContainer = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "auto")};
  padding: ${({ padding }) => (padding ? padding : "30px 80px 30px 80px")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#FFFCF5"};
  background-position: center;
  background-size: cover;
  border: ${({ border }) => (border ? border : "none")};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "0px")};

  @media ${breakpointSizes.mobile} {
    padding: 20px;
  }

  @media ${breakpointSizes.desktop} {
    padding: 2% 20%;
  }
`;

export const CardBody = styled.div`
  width: ${({ width }) => (width ? width : "714px")};
  // height: ${({ height }) => (height ? height : "250px")};
  border: ${({ border }) => (border ? border : "1px solid #E3E4EA")};
  margin-bottom: 30px;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  padding: 24px 22px 22px 22px;
  .card_header {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  .header {
    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.48px;
    }
    p {
      margin-top: -15px;
      font-size: 14px;
      color: var(--neutral-600, #525252);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  h2 {
    margin-top: 0;
  }
  .selectorWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 20px;
  }
  .dateWrapper {
    display: flex;
    gap: 30px;
    width: 100%;
    label {
      display: flex;
      flex-direction: row-reverse;

      // justify-content: space-around;
      // border: 3px solid red;
      span {
        display: block !important;
      }
    }
  }
  .acceptedDate {
    display: flex;
    gap: 7px;
  }
  .dateData {
    background: #ff99e4;
    border: 1px solid #dfe0e0;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 15px;
    color: white;
    top: 0px;
    z-index: 100;
    position: sticky;
  }
  .dateBody1 {
    width: 100%;
    height: 330px;
    overflow-y: auto;
    position: relative;
  }
  .dateContent {
    border: 1px solid #dfe0e0;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 8px;
  }
  .dateBody {
    // overflow-y: hidden;
    border: 1px solid #dfe0e0;
    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
      border: none !important;
    }
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root:focus,
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: rgb(255, 153, 228) !important;
    }
    .selected-day {
      background-color: rgb(255, 153, 228) !important;
    }
    .Mui-selected {
      background-color: rgb(255, 153, 228) !important;
    }
    .Mui-selected .MuiButton-label {
      color: white;
    }
    border-radius: 8px;
    // flex: 1;
    .css-flbe84-MuiDayCalendar-weekContainer {
      // margin: 20px;
      button {
        color: #222;
        font-size: 16px;
        // font-family: reboundBold;
        font-weight: 600;
      }
    }
    .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
      // overflow-x: visible !important;
    }

    .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
    .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background: #ffc5ef;
    }
    .css-cwhad8-MuiDateCalendar-root {
      overflow: visible !important;
      .css-nk89i7-MuiPickersCalendarHeader-root {
        display: flex;
        .css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
          width: 300px;
          overflow: visible !important;
          .css-31ca4x-MuiPickersFadeTransitionGroup-root {
            .css-dplwbx-MuiPickersCalendarHeader-label {
              white-space: nowrap;
              color: #222;
              font-family: reboundBold;
              font-size: 22.75px;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
      .css-sf5j28-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
      }
      .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
        display: none;
      }
    }
    .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
    .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      padding: 0 !important;
      background-color: inherit !important;

      &:hover {
        background-color: none !important;
      }
      span {
        display: none !important;
      }
    }
    .css-9reuh9-MuiPickersArrowSwitcher-root {
      background-color: transparent !important;
      display: flex;
      gap: 9px;
      text-align: left;
      justify-content: start;
      width: 46px;
      margin-right: 50px;
      &:hover {
        background-color: none;
      }
      svg {
        margin-left: 10px;
        border-radius: 100%;
        background: #ffc5ef;
        box-shadow: 0px 1.625px 6.5px 0px rgba(190, 190, 190, 0.15);
        width: 26.105px;
        height: 26px;
        &:hover {
          background-color: none;
        }
      }
    }
    .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    }
  }
  .selector {
    border: 1px solid #dfe0e0;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  label {
    display: flex;
    flex-direction: row-reverse;
    span {
      display: block !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f13dd4;
    border: 1px solid #ffffff;
  }
  .date {
    border: 4px solid red;
    display: absolute;
    z-index: 2000;
    height: 800px;
  }
  @media max-width: 768px {
    .dateWrapper {
      flex-wrap: wrap;
    }
  }
  @media ${breakpointSizes.mobile} {
    width: 100%;
    padding: 20px;
    .dateBody {
      width: 90%;
      border: none;
    }
    .dateWrapper {
      flex-wrap: wrap;
    }
    .selectorWrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const CardContainer = styled.div`
  @media ${breakpointSizes.mobile} {
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export const HeadingLarge = styled.h1`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "0px")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "24px")};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Bold")};
  color: #1a1a1a;

  @media ${breakpointSizes.mobile} {
    font-size: 22px;
    line-height: 36px;
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export const HeadingSmall = styled.h4`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "24px")};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Medium")};
  color: ${({ color }) => (color ? color : "#1A1A1A")};
  margin: ${({ margin }) => (margin ? margin : "0 0 0 0")};

  @media ${breakpointSizes.mobile} {
    font-size: 20px;
    line-height: 36px;
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export const Paragraph = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "24px")};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Light")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "10")};

  color: ${({ color }) => (color ? color : "#2D2B2B")};
  margin: ${({ margin }) => (margin ? margin : "0px 0px 0px 0px")};

  @media ${breakpointSizes.mobile} {
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export const NiyoButton = styled.button`
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Bold")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "24px")};
  color: ${({ color }) => (color ? color : "#FFFCF5")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#1A1A1A"};
  margin: ${({ margin }) => (margin ? margin : "0px 0px 0px 0px")};
  margin-top: ${({ marginT }) => (marginT ? marginT : "0px")};
  margin-left: ${({ marginL }) => (marginL ? marginL : "0px")};
  margin-bottom: ${({ marginB }) => (marginB ? marginB : "0px")};
  width: ${({ width }) => (width ? width : "210px")};
  transition: 0.2s ease-in;
  height: ${({ height }) => (height ? height : "56px")};
  border: ${({ border }) => (border ? border : "none")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "16px"};
  cursor: pointer;
  :hover {
    background-color: #292e3c;
  }

  /* Position the tooltip */
  position: relative;

  /* Show tooltip text on hover */
  &:hover::after {
    content: attr(title); /* Use the title prop as the content */
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    background-color: #1A1A1A;
    color: #FFFCF5;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1.4;
    opacity: ${({ title }) => (title ? "0.9" : "0")};
    z-index: 1; /* Ensure tooltip appears above other elements */
  }

  /* Hide hover text by default */
  &::after {
    content: '';
    opacity: 0;
    pointer-events: none;
  }

  /* Adjust tooltip position for smaller screens */
  @media (max-width: 768px) {
    &:hover::after {
      top: -4px;
      left: 0;
      transform: none;
    }
  }

  &:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }

  @media ${breakpointSizes.mobile} {
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Medium")};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export const SpaceLarge = styled.div`
  height: 150px;
  width: 100px;
`;

export const SpaceMedium = styled.div`
  height: 80px;
  width: 100px;
  @media ${breakpointSizes.mobile} {
    height: 50px;
  }
`;

export const SpaceSmall = styled.div`
  height: 30px;
  width: 100px;
  @media ${breakpointSizes.mobile} {
    height: 15px;
  }
`;
