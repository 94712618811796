/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import { BookingLayoutContainer } from "./style";
import office1 from "../../asset/salon-1.jpeg";
import office2 from "../../asset/salon-2.jpeg";
import office3 from "../../asset/salon-3.jpeg";
import office4 from "../../asset/salon-4.jpeg";
import Info from "../../asset/Pen.svg";
import { BannerCarousel } from "../banner-carousel";
import { HeadingLarge, Paragraph } from "../../styles/StyledComponents";
import { useSelector, useDispatch } from "react-redux";
import { calendar } from "../../api/appointment";

const BookingLayout = ({ children }) => {
  const dispatch = useDispatch();

  const [BookTotal, setBookTotal] = useState();

  const { totalPrice } = useSelector((state) => state.hairstyle);
  const { daysData } = useSelector((state) => state?.appointment);

  const total = () => {
    const hairPrice = localStorage.getItem("hairPrice");
    const price = hairPrice ? parseInt(hairPrice, 10) : 0;
    setBookTotal(price + totalPrice);
  };

  useEffect(() => {
    total();
    function fetchData() {
      dispatch(calendar());
    }
    fetchData();
  }, [dispatch, totalPrice]);

  const Banners = [
    <img src={office1} alt="banner" />,
    <img src={office2} alt="banner" />,
    <img src={office3} alt="banner" />,
    <img src={office4} alt="banner" />,
  ];
  return (
    <BookingLayoutContainer>
      <div className="content-tab">{children}</div>

      <div className="address-tab">
        <div className="inner-content">
          <div className="office-pic">
            <BannerCarousel carouselItems={Banners} />
          </div>
          <br />
          <div className="hair_total">
            <div>
              <div>
                <img src={Info} alt="info" />
              </div>
              <HeadingLarge>
                Current Price of Hairstyle:£ {BookTotal}
              </HeadingLarge>
            </div>
          </div>
          <HeadingLarge>
            First AR-Powered Afro Hair & Beauty Salon Space
          </HeadingLarge>{" "}
          <br />
          <Paragraph>
            Niyo Hair & beauty salon is the 1st AR Powered Beauty Salon in the
            world
          </Paragraph>
          <HeadingLarge>ADDRESS</HeadingLarge>
          <Paragraph>
            UG05, Zellig Building, Gibb St, Deritend, Birmingham B9 4AT
          </Paragraph>
          <HeadingLarge>PHONE NUMBER</HeadingLarge>
          <Paragraph>0121 828 9210</Paragraph>
          <br />
          <HeadingLarge>OPENING TIMES</HeadingLarge>
          <div className="opening-hours">
            {daysData
              ?.filter((day) => !day.blocked)
              .map((day) => (
                <React.Fragment key={day.id}>
                  <Paragraph className="day">{day.day}</Paragraph>
                  <Paragraph>
                    {day.openTime} - {day.closeTime}
                  </Paragraph>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </BookingLayoutContainer>
  );
};

export default BookingLayout;
