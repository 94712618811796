const size = {
  small: "768px",
  medium: "1000px",
  large: "1900px",
};

const breakpointSizes = {
  mobile: `(max-width: ${size.small})`,
  tablet: `(max-width: ${size.medium})`,
  desktop: `(min-width: ${size.large})`,
};

export default breakpointSizes;
