import React, { useMemo, useEffect } from 'react'
import './App.css'
import jwt_decode from 'jwt-decode'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Booking from './pages/Booking '
import Login from './pages/MiniDashboard'
import Dashboard from './pages/MiniDashboard/components/dashboard'
import Bookings from './pages/MiniDashboard/components/singleBook'
import Referral from './pages/MiniDashboard/components/singleRef'
import ProfileDetails from './pages/MiniDashboard/components/profile'
import Preference from './pages/Booking /Preference'
import RequireAuth from './utils/RequiredAuth'
import Layout from './pages/Layout'
import Information from './pages/Booking /Information'
import Payment from './pages/Booking /Payment'
import Review from './pages/Booking /Review'
import { useDispatch } from 'react-redux'
import InactivityLogout from './utils/InactivityLogout';
import { logout } from './globalState/reducers/dashboardSlice'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const expiry = useMemo(() => {
    const token = localStorage.getItem('userToken')
      ? localStorage.getItem('userToken')
      : null

    const decodedToken: any = token && jwt_decode(token)
    const currentDate = new Date()

    return decodedToken?.exp * 1000 < currentDate.getTime()
  }, [])


  const handleLogout = () => {
    dispatch(logout())
  };
  useEffect(() => {
    if (expiry) {
      dispatch(logout())
      // navigate('/login');
    }
  }, [expiry, dispatch])
  return (
    <>
      <Router>
      <InactivityLogout onLogout={handleLogout} />
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Booking />
              </Layout>
            }
          ></Route>

          <Route path="/login" element={<Login />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route element={<RequireAuth />}>
            <Route path="/bookings/:id" element={<Bookings />}></Route>
            <Route path="/referral" element={<Referral />}></Route>
            <Route path="/profile" element={<ProfileDetails />}></Route>
          </Route>

          <Route
            path="/preference/:name"
            element={
              <Layout>
                <Preference />
              </Layout>
            }
          ></Route>
          <Route
            path="/preferenze"
            element={
              <Layout>
                <Preference />
              </Layout>
            }
          ></Route>
          <Route
            path="/review"
            element={
              <Layout>
                <Review />
              </Layout>
            }
          ></Route>
          <Route
            path="/information"
            element={
              <Layout>
                <Information />
              </Layout>
            }
          ></Route>
          <Route
            path="/payment"
            element={
              <Layout>
                <Payment />
              </Layout>
            }
          ></Route>
        </Routes>
      </Router>
    </>
  )
}

export default App
