import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
     

export const hairstyleCat = createAsyncThunk(
  'fetchHairstyleCat',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/hairstyles/hair/categories`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const allHairstyles = createAsyncThunk(
  'fetchAllHairstyles',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/hairstyles/hair/public`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const catHairstyle = createAsyncThunk(
  'fetchCatHairstyle',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/hairstyles/hair/categories/${id}`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const hairstyle = createAsyncThunk(
  'fetchHairstyle',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/hairstyles`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const hairstylePreference = createAsyncThunk(
  'fetchHairstylepreference',
  async (name,{ rejectWithValue }) => {
    try {
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/hairstyles/${name}/preferences`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)