import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
     

export const createIntent = createAsyncThunk(
  'createIntent',
    async ({ id }, { rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.post(
        `${BASE_URL}/api/v1/payment/${id}/stripe/intent`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error.message)
    }
  }
)

export const confirmBooking = createAsyncThunk(
  'confirmBooking',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/booking/preview/${id}/confirm`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
export const previewAppointment = createAsyncThunk(
  'fetchAppointmentPreview',
    async ({ id }, { rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking/preview/${id}`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error.message)
    }
  }
)

export const payment = createAsyncThunk(
    'paymentIntent',
    async ({ id }, { rejectWithValue }) => {
      try {
        const { data:{ data } } = await client.get(
            `${BASE_URL}/api/v1/payment/${id}/stripe/checkout`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )