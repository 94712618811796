import styled from "styled-components";
import office from "../../asset/office.png";
import breakpointSizes from "../../constants/breakpointSizes";

export const HeadingLarge = styled.h1`
  font-size: 22px;
  line-height: 24px;
  font-family: Bold;
  color: #1a1a1a;

  @media ${breakpointSizes.mobile} {
    font-size: 35px;
    line-height: 36px;
  }

  @media ${breakpointSizes.desktop} {
  }
`;
export const NiyoButton = styled.button`
  font-size: 13px;
  line-height: 24px;
  color: #fffcf5;
  background-color: #1a1a1a;
  margin: 0px;
  width: 100%;
  transition: 0.2s ease-in;
  // width: 413px;
  height: 55px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  :hover {
    background-color: #292e3c;
  }

  @media ${breakpointSizes.mobile} {
  }

  @media ${breakpointSizes.desktop} {
  }
`;

export const BookingLayoutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  .tabs {
    width: 100%;
    height: 40px;
    color: #2d2b2b;
    font-family: Light;
    ul {
      display: flex;
      padding-left: 0px;
      li {
        padding-right: 1rem;
        cursor: pointer;
        list-style: none;
        transition: all 0.7s;
        span {
          background-color: #ff47ce;
          padding: 3px 8px;
          border-radius: 100%;
          color: #fff;
        }
      }
      li.active {
        span {
          background-color: transparent;
          border: 1px solid #ff47ce;
          color: #a4a6a7;
        }
      }
      @media (max-width: 768px) {
        width: 90%;
      }
    }
    @media (max-width: 769px) {
      padding: 2rem 0;
    }
  }
  .content-tab {
    width: 55%;
    height: auto;
    
    border-radius: 8px;
    padding: 10px;
  }

  // .content-tab {
  //   width: 65%;
  //   height: 20px;
  //   border: 1px solid #e3e4ea;
  //   border-radius: 8px;
  //   padding: 10px;
  // }

  .address-tab {
    width: 30%;
    height: max-content;
    position: sticky;
    top: 0; /* Adjust this value as needed, it could be the height of your navbar if you have one */
    overflow-y: auto;
    max-height: 100vh; 
  
    .inner-content {
      border: 1px solid #e3e4ea;
      border-radius: 8px;
      padding: 15px 15px 40px 15px;
      .hair_total {
      padding-bottom: 8px;
        div {
          border 1px solid #EFF0FF;
          padding: 0px 7px;
          width: fit-content;
          border-radius: 24px;
          background: #EFF0FF;
          border-radius: 24px;
          color: #4353FF;
          display: flex;
        h1 {
          color: #4353FF !important;
        }
        }
      }
    }
    .btn {
      margin-top: 20px;
      margin-bottom: 20px;
      }
    .office-pic {
      // background-image: url(${office});
      // height: 200px;
      width: 100%;
     
      // background-size: cover;
      border-radius: 8px;
      img {
        width: 100%;
         object-fit: cover;
      height: 200px;
      border-radius: 8px;
      }
    }
    h1 {
      margin-bottom: 0px !important;
    }
    .opening-hours {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .day {
        width: 60%;
      }
      p {
        width: 40%;
        margin-top: 10px;
        margin-bottom:5px !important
      }
    }
  }
  .address-tab::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .address-tab {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  @media ${breakpointSizes.mobile} {
flex-wrap: wrap;
    .content-tab {
      width: 100%;
      padding:0;
    }
    .address-tab {
      width:100%;
      .inner-content{
        display: none;
      }
      .btn{
        width:100%;
      }
    }
  }
`;