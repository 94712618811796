import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
     

export const getBook = createAsyncThunk(
  'fetchBook',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking/user/booked`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const getAuth = createAsyncThunk(
  'fetchAuth',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/users/auth/shake`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const auth = createAsyncThunk(
  'sendAuth',
  async ({ email },{ rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data: {data} } = await client.post(
        `${BASE_URL}/api/v1/auth/user-auth`,
        { email },  config
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getDiscount = createAsyncThunk(
  'requestDiscount',
  async (obj,{ rejectWithValue }) => {
    try {
      const { data: { data } } = await client.post(
        `${BASE_URL}/api/v1/referral/user/referred/discounts`
      )
      return data
    } catch (error) {
      throw rejectWithValue(error)
    }
  }
)

export const referral = createAsyncThunk(
  'fetchReferral',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/referral/user/referred`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const singleBook = createAsyncThunk(
  'fetchSingleBook',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking/user/booked/${id}`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error)
    }
  }
)

export const schedule = createAsyncThunk(
  'reschedule',
  async ({ bookId, hairId, schedule },{ rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data: {data} } = await client.patch(
        `${BASE_URL}/api/v1/booking/user/booked/${bookId}/schedule`,
        { schedule },  config
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const cancelBooking = createAsyncThunk(
  'cancleBooking',
  async ({ bookId },{ rejectWithValue }) => {
    try {
      const { data: {data} } = await client.put(
        `${BASE_URL}/api/v1/booking/user/booked/${bookId}/cancel`
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)