import { useEffect, useRef, useState } from 'react';
import { CarouselWrapper } from './styles';
import { nanoid } from "nanoid";

export const BannerCarousel = ({ carouselItems = [], ids = [] }) => {
  const [index, setIndex] = useState(0);
  const [tabContentLeft, setTabContentLeft] = useState(0);
  const ref = useRef({});
  const [, setTimerId] = useState(null);

  const scrollToIndex = index => {
    const selected = ref.current[index];

    if (!selected) return;

    const width = selected.clientWidth + 8;
    setTabContentLeft(index * width);
  };

  const stopTimer = () => {
    setTimerId(oldId => {
      clearInterval(oldId);
      return null;
    });
  };

  const startTimer = () => {
    stopTimer();

    const newTimer = setInterval(() => {
      setIndex(oldIndex => {
        let newIndex = oldIndex + 1;
        if (newIndex >= carouselItems.length) {
          newIndex = newIndex % carouselItems.length;
        }

        scrollToIndex(newIndex);

        return newIndex;
      });
    }, 5000);

    setTimerId(newTimer);
  };


  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  useEffect(() => {
    startTimer();
  }, [carouselItems]);

  return (
    <CarouselWrapper>
      <div
        className="_items_holder"
        style={{
          transform: `translateX(-${tabContentLeft}px)`,
        }}
      >
        {carouselItems.map((item, index) => {
          return (
            <div
              className="_item_holder"
              key={nanoid()}
              ref={element => {
                ref.current[index] = element;
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
    </CarouselWrapper>
  );
};
